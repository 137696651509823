import React from 'react';
import PageHeader from "./PageHeader";
import './BrandPage.css';
import logo from "../img/logos/logo-2.png";
import AlbumRow from "./products/AlbumRow";

export default class MediaOther extends React.Component {
  constructor(){
    super();
  }
  render(){
    return (
      <div className="product-page">
        <PageHeader title={"Other Products"}/>
        <div className="product-page-container">
          <AlbumRow title = 'Zephair Connector'
          files={[
            require('../img/media/purdoux/zephair_connector/Zephair Hose Connector Image.jpg').default,
            require('../img/media/purdoux/zephair_connector/Zephair Connector Airmini.jpg').default,
            require('../img/media/purdoux/zephair_connector/Zephair Connector w Hose.jpg').default,
            require('../img/media/purdoux/zephair_connector/Zephair Hose Connector Packaging.jpg').default
          ]}/>
        </div>
      </div>
      
    );
  }
}