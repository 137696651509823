import React from 'react';
import '../common.css';

export default class AlbumRow extends React.Component {
  constructor(){
    super();
    this.state = {
      height: window.innerHeight,
      width: window.innerWidth
    }
    this.handleResize = this.handleResize.bind(this);
  }
  handleResize(){
    this.setState({
      ['height']: window.innerHeight
    });
    this.setState({
      ['width']: window.innerWidth
    });
  }
  
  
  render(){
    window.addEventListener('resize', this.handleResize);
    let gridRows = [];
    let gridRow = [];
    let cols = 2;
    if(this.state.width>1180){
      cols = 5;
    }else if(this.state.width<=1180&&this.state.width>828){
      cols =4;
    }else if(this.state.width<=828&&this.state.width>638){
      cols = 3;
    }
    for(let file in this.props.files){
      gridRow.push(<a href={this.props.files[file]}><img src={this.props.files[file]}/></a>);
      if(file%cols==cols-1||file==this.props.files.length-1){
        gridRows.push(<div className="grid-row">{gridRow}</div>);
        gridRow=[];
      }
    }

    return (
      <div className="row">
          <div className="row">
            <h3 className="media-row-title" style={{marginLeft: '30px'}}>{this.props.title}</h3>
          </div>
          <div className="grid">
            {gridRows}
          </div>
          <p style={{width:'100%'}}><hr/></p>
      </div>
    );
  }
}