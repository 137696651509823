
import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
export default class BestInRest extends React.Component {
  constructor(){
    super();
  }
  
  state = {
      name:'',
      email:'',
      phonenum:'',
      type:'',
      nature:'',
      company:'',
      message:'',
      captcha:'',
      emailStatus:''
  }

  errorCaptcha=(error)=>{
      console.log(error);
  }

  handleCaptcha = (response) => {
    //console.log(response);
    try{
        this.setState({
            ['captcha']: response
        });
    }catch(e){
        console.error(e);
    }
  }

  handleChange = input => e => {
     // console.log(e.target.value);
      this.setState({
         [input]: e.target.value
      });
  }

  //submits the form

  submitForm = (e) => {
    const {name, email, phonenum, type, nature, company, message, captcha} = this.state;
    //create a new XMLHTTPRequest
    var xhr = new XMLHttpRequest;
    //get callback when server responds
    xhr.addEventListener('load', ()=>{
        //update emailStatus with response
        //console.log(xhr.responseText)
        this.setState({
            emailStatus: xhr.responseText
        });
    });
    xhr.open('GET', 'http://api.choiceonemedical.com/sendmail/index.php?sendto=' + email + 
        '&name=' + name + 
        '&email=' + email + 
        '&phonenum=' + phonenum + 
        '&type=' + type +
        '&nature=' + nature + 
        '&company=' + company + 
        '&message=' + message + 
        '&captcha=' + captcha
    );

    
    //send the request
    xhr.send();
    e.preventDefault();
  }
  

  render(){
    const {name, email, phonenum, type, nature, company, message, captcha, emailStatus} = this.state;
    return (
        <div className="request-form">
            <form className="contact-form" onSubmit={this.submitForm}>
            <div className="row">
                <label>Name <b>{name ? null : "*"}</b></label>
                <input className="input" type="name" placeholder="enter your name" value={name} onChange={this.handleChange('name')}/>
                
            </div>
            <div className="row">
                <div className="col">
                <label>E-mail <b>{email ? null : "*"}</b></label>
                <input className="input" type="email" placeholder="enter your e-mail" value={email} onChange={this.handleChange('email')}/>
                </div>
                <div className="col">
                <label>Telephone Number <b>{phonenum ? null : "*"}</b></label>
                <input className="input" type="phone num" placeholder="enter your phone-number" value={phonenum} onChange={this.handleChange('phonenum')}/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                <label>Nature of inquiry <b>{nature ? null : "*"}</b></label>
                <select className="input" name="nature" value={nature} onChange={this.handleChange('nature')}>
                    <option>Select One</option>
                    <option value="provider">CPAP Provider Business</option>
                    <option value="patient">CPAP Patient</option>
                </select>
                </div>
                <div className="col">
                <label>Type <b>{type ? null : "*"}</b></label>
                <select className="input" name="type" value={type} onChange={this.handleChange('type')}>
                    <option>Select One</option>
                    <option value="dealer">Become a dealer</option>
                    <option value="question">Technical question</option>
                    <option value="other">Other</option>
                </select>
                </div>
            </div>
            <div className="row">
                <label>Company Name</label>
                <input className="input" type="company" value={company} placeholder="company name" onChange={this.handleChange('company')}/>
            </div>
            <div className="row">
                <label>Message <b>{message ? null : "*"}</b></label>
                <textarea className="input" type="message" value={message} placeholder="your message here" onChange={this.handleChange('message')} maxLength="300"/>
            </div>
            <div className="row">
            <ReCAPTCHA
                    sitekey="6Ld1YsYZAAAAAJUeOxgiasRmQq0a-R2TS66QpA_b"
                    onChange={this.handleCaptcha}
                    onErrored={this.errorCaptcha}
                />
            </div>
            <div className="row">
            {!emailStatus.includes("Message has been sent") &&
                <label>
                    <input type="submit" className="submitBtn" value="Send request"/>
                </label>
            }
            </div>
            </form>
            {emailStatus ? emailStatus : null}
      </div>
    );
  }
}
