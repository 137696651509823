import React from 'react';
import './ProductRow.css';
import {
  BrowserRouter as Router,
  Link
} from 'react-router-dom';


export default class ProductRow extends React.Component {
  constructor(){
    super();
  }
  render(){
    const url = "/products/" + this.props.obj.brand + "/"+this.props.obj.url;
    return ( 
      <div className="product-row">
        <hr/>
        <div className="product-row-container">
          <div className="product-image">
            <Link to={url}>
              <img src={require("../"+this.props.obj.thumbnail).default} alt="Product-Image"/>
            </Link>
          </div>
          <div className="product-info">
            <h2>{this.props.obj.title}</h2>
            <div className="product-description">
              {this.props.obj.description}
            </div>
            <Link to={url}>
              <button variant="success">Read More</button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}