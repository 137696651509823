import React from 'react';
import * as ReactBootStrap from "react-bootstrap";
import '../ProductPage.css';
import onlyCAN from "../../img/only_in_canada1.png";
import newTwenty from "../../img/new2020-label.png"
import {
    Link
  } from "react-router-dom";

export default class SmallDescription extends React.Component {
  constructor(){
    super();
  }
  render(){
    return (
        <div>
            {this.props.objID=="airsense11kit" &&
                <div className="small-description">
                    <p><b>ResMed AirSense 11 Kit for Pilot-24 Lite</b></p>
                    <p>Medistrom Kit for Pilot-24 Lite for ResMed AirSense 11 PAP devices. Includes DC Output and Short Charging Adapter cable. </p>
                    <p><b>Items Included:</b></p>
                    <p> 
                        <ul>
                            <li>DC Output Cable for ResMed AirSense 11</li>
                            <li>Short Charging Adapter Cable for ResMed Airsense 11</li>
                        </ul>
                    </p>
                </div>
            }
            {this.props.objID=="ds2cable" &&
                <div className="small-description">
                    <p><b>Respironics DreamStation 2 Cable for Pilot-12 Lite </b></p>
                    <p>This Cable allows the Pilot-12 Lite to be used with Philips DreamStation 2 PAP Devices.</p>
                    <p><b>Items Included:</b></p>
                    <p> 
                        <ul>
                            <li>DC Output Cable for Philips Respironics DreamStation 2</li>
                        </ul>
                    </p>
                </div>
            }

            {this.props.objID=="lowensteincable" &&
                <div className="small-description">
                    <p><b>Löwenstein Medical prismaSMART Kit</b></p>
                    <p>Medistrom Kit for Pilot-24 Lite for Löwenstein prismaSMART PAP devices. Includes DC Output and Short Charging Adapter cable for Löwenstein prismaSMART series devices.</p>
                    <p><b>Items Included:</b></p>
                    <p> 
                        <ul>
                            <li>DC Output Cable for Löwenstein prismaSMART</li>
                            <li>Short Charging Adapter Cable for Löwenstein prismaSMART</li>
                        </ul>
                    </p>
                </div>
            }
            {this.props.objID=="pilot12lite" &&
                <div className="small-description">
                    <p>New <b>Medistrom™ Pilot-12 Lite</b> – revolutionary small Backup Power Supply/ Portable Travel Battery for Philips Respironics, DeVilbiss, and other 12V PAP Devices.</p>
                    <br/>
                    <Link to="/pilot_manual">
                        <ReactBootStrap.Button className="pdfbutton" variant="success">Manual</ReactBootStrap.Button>
                    </Link>
                    <Link to="/pilot_quickstart">
                        <ReactBootStrap.Button className="pdfbutton" variant="success">Quick Start Guide</ReactBootStrap.Button>
                    </Link>
                    <Link to="/pilot_travel_letter">
                        <ReactBootStrap.Button className="pdfbutton" variant="success">Travel Letter</ReactBootStrap.Button>
                    </Link>
                </div>
            }
            {this.props.objID=="pilot24lite" &&
                <div>
                    <div className="small-description">
                    <p>New <b>Medistrom™ Pilot-24 Lite</b> – revolutionary small Backup Power Supply/ Portable Travel Battery for ResMed and other 24V PAP Devices.</p>
                    <br/>
                    <Link to="/pilot_manual">
                        <ReactBootStrap.Button className="pdfbutton" variant="success">Manual</ReactBootStrap.Button>
                    </Link>
                    <Link to="/pilot_quickstart">
                        <ReactBootStrap.Button className="pdfbutton" variant="success">Quick Start Guide</ReactBootStrap.Button>
                    </Link>
                    <Link to="/pilot_travel_letter">
                        <ReactBootStrap.Button className="pdfbutton" variant="success">Travel Letter</ReactBootStrap.Button>
                    </Link>
                </div>
                </div>
            }
            {this.props.objID=="carcharger" &&
                <div className="small-description">
                    <p><b>Medistrom™ Car Charger for Pilot-12/24 Lite</b></p>
                    <p>Traveling to places where mains power is not available? No Problem! You can charge your Pilot-12/24 Lite Battery from your vehicle's 12V cigarette lighter socket!</p>
                </div>
            }
            {this.props.objID=="solarpanel" &&
                <div className="small-description">
                    <p><b>Medistrom™ 50W Solar Panel for Pilot-12/24 Lite</b></p>
                    <p>Traveling to places where mains power is not available? No Problem! You can charge your Pilot-12/24 Lite Battery via Solar Power</p>
                    <p>50W Solar Panel for Medistrom Pilot-12 Lite and Pilot-24 Lite Backup Power Supplies/ CPAP Batteries. Charge time will vary based on weather and amount of available sunlight. Please make sure to place the Solar Panel in direct sunlight for optimal charge times. To be used only for charging, not intended to be used in the backup power supply configuration. </p>
                </div>
            }
            {this.props.objID=="s9kit" &&
                <div className="small-description">
                    <p><b>Medistrom™ ResMed S9 Kit for Pilot-24 Lite</b></p>
                    <p>Items Included:</p>
                    <p> 
                        <ul>
                            <li>DC Output Cable for ResMed S9</li>
                            <li>Short Charging Adapter Cable for ResMed S9</li>
                        </ul>
                    </p>
                </div>
            }
            {this.props.objID=="dreamstation" &&
                <div className="small-description">
                    <p><b>Medistrom™ Respironics DreamStation Go and 3B Medical Luna Kit for Pilot-24 Lite</b></p>
                    <p>Items Included:</p>
                    <p> 
                        <ul>
                            <li>DC Output Cable for Philips Respironics DreamStation Go</li>
                            <li>DC Output Cable for 3B Medical Luna </li>
                            <li>AC Power Adapter for Medistrom Pilot-24 Lite </li>
                        </ul>
                    </p>
                </div>
            }
            {this.props.objID=="ichkit" &&
                <div className="small-description">
                    <p><b>Medistrom™ Apex Medical iCH Kit for Pilot-24 Lite</b></p>
                    <p>Items Included:</p>
                    <p> 
                        <ul>
                            <li>DC output cable for Apex Medical iCH PAP Devices </li>
                            <li>Short Charging Adapter Cable for Apex Medical iCH PAP Devices </li>
                        </ul>
                    </p>
                </div>
            }
            {this.props.objID=="acadapter" &&
                <div className="small-description">
                <p><b>Medistrom™ AC Power Adapter for Pilot-24 Lite</b></p>
                <p> 
                This is an AC Power Adapter with 2 pin Type A prongs (Used in the Canada, USA, Mexico and Japan) for the Pilot-24 Lite. AC Input Voltage from 100-240V 50/60Hz 2.5A. DC Output 24V 3.75A. 
                </p>
            </div>
            }
            {this.props.objID=="mixedkit" &&
                <div className="small-description">
                    <p>Items Included:</p>
                    <p> 
                        <ul>
                            <li>DC Output Cable for DeVilbiss IntelliPAP I and HDM Z1 </li>
                            <li>Short Charging Adapter Cable for DeVilbiss IntelliPAP I and HDM Z1 </li>
                            <li>DC Output Cable for DeVilbiss IntelliPAP II </li>
                        </ul>
                    </p>
                </div>
            }
            {this.props.objID=="handsanitizer" &&
                <div className="small-description">
                    <p>A refreshing unscented formula enriched with Aloe Vera</p>
                    <p>Authorized by Health Canada</p><br/>
                    <p>USP Quality Ethyl Alcohol 62%</p> 
                    <p>Available Size: 500ml</p>
                    <img src={onlyCAN}/>
                </div>
            }
            {this.props.objID=="hosestandard" &&
                <div className="small-description">
                    <p>Pürdoux™ Standard 6-foot CPAP hose is suitable for all PAP devices that have a setting for 22mm tubing diameter. Designed for high flexibility, reduced leaks and better mask seal.</p>
                    <p>Pürdoux ™ hoses are smooth and durable on the inside to produce the best results. The ergonomic design of the hose is intended to provide consistent pressure and to reduce noise.</p>
                    <h3>Features</h3>
                    <p>
                        <ul>
                            <li>Made with premium rubber ends</li>
                            <li>Ergonomic design</li>
                            <li>Ends are specifically shaped to easily attach and remove</li>
                            <li>User friendly</li>
                        </ul>
                    </p>
                </div>
            }
            {this.props.objID=="hoseslim" &&
                <div className="small-description">
                    <p>Pürdoux™ PürSlim 6-foot CPAP hose is suitable for all PAP devices that have a setting for 15mm tubing diameter. Designed for high flexibility, reduced leaks and better mask seal.</p>
                    <p>Pürdoux hoses are smooth and durable on the inside to produce the best results. The ergonomic design of the hose is intended to provide consistent pressure and to reduce noise. </p>
                    <h3>Features</h3>
                    <p>
                        <ul>
                            <li>Made with premium rubber ends</li>
                            <li>Ergonomic design</li>
                            <li>Ends are specifically shaped to easily attach and remove</li>
                            <li>User friendly</li>
                        </ul>
                    </p>
                </div>
            }
            {this.props.objID=="aloecan" &&
                <div className="small-description">
                    <p>70 high quality 100% pure cotton wipes with high absorbency level </p>
                    <p>PÜRDOUX™ CPAP Mask Wipes are made without the use of harmful chemicals and are designed for daily cleaning of your CPAP Mask. Our unscented wipes are made with aloe vera making it very soft for cleaning and moisturizing your hands.</p>
                    <h3>Features</h3>
                    <p>
                        <ul>
                            <li>Alcohol and Solvent free </li>
                            <li>Gentle on hands and silicone surfaces </li>
                            <li>Ready to use </li>
                            <li>100% cotton wipes </li>
                            <li>Natural Ingredients with Aloe Vera: </li>
                        </ul>
                    </p>
                    Water, Glycerin, Propylene Glycol, Chamomilla Recutita Flower Extract, Aloe Barbadensis Flower Extract, Tocopheryl Acetate, Polyaminopropyl Biguanide, Phenoxyethanol, Citric Acid, PEG-40 Hydrogenated Castor Oil
                </div>
            }
            {this.props.objID=="citruscan" &&
                <div className="small-description">
                    <p>70 high quality 100% pure cotton wipes with high absorbency level</p>
                    <p>Purdoux™ CPAP Mask Wipes are made without the use of harmful chemicals and are designed for daily cleaning of your CPAP Mask. Our scented wipes are made with grapefruit and lemon leaving a pleasant aroma behind.</p>
                    <h3>Features</h3>
                    <p>
                        <ul>
                            <li>Alcohol and Solvent free </li>
                            <li>Gentle on hands and silicone surfaces </li>
                            <li>Ready to use </li>
                            <li>100% cotton wipes </li>
                            <li>High absorbancy level</li>
                            <li>Natural ingredients with grapefruit and lemon:  </li>
                        </ul>
                    </p>
                    Water, Glycerin, Propylene Glycol, Chamomilla Recutita Flower Extract, Aloe Barbadensis Flower Extract, Citrus Paradisi Fruit Extract, Tocopheryl Acetate, Polyaminopropyl Biguanide, Phenoxyethanol, Citric Acid, PEG-40 Hydrogenated Castor Oil, Fragrance.
                </div>
            }
            {this.props.objID=="aloetravel" &&
                <div className="small-description">
                    <p>12 Sealed Sachets (10 Wipes each) high quality 100% pure cotton wipes with high absorbency level.</p>
                    <p>CPAP Mask Wipes are made without the use of harmful chemicals and are designed for daily cleaning of your CPAP Mask. Our unscented wipes are made with aloe vera making it very soft for cleaning and moisturizing your hands. </p>
                    <h3>Features</h3>
                    <p>
                        <ul>
                            <li>Alcohol and Solvent free </li>
                            <li>Gentle on hands and silicone surfaces </li>
                            <li>Ready to use </li>
                            <li>100% cotton wipes </li>
                            <li>High absorbancy level</li>
                            <li>Natural Ingredients with Aloe Vera:  </li>
                        </ul>
                    </p>
                    Water, Glycerin, Propylene Glycol, Chamomilla Recutita Flower Extract, Aloe Barbadensis Flower Extract, Tocopheryl Acetate, Polyaminopropyl Biguanide, Phenoxyethanol, Citric Acid, PEG-40 Hydrogenated Castor Oil. 
                </div>
            }
            {this.props.objID=="citrustravel" &&
                <div className="small-description">
                    <p>12 Sealed Sachets (10 Wipes each) high quality 100% pure cotton wipes with high absorbency level.</p>
                    <p>PÜRDOUX™ ™ CPAP Mask Wipes are made without the use of harmful chemicals and are designed for daily cleaning of your CPAP Mask. Our scented wipes are made with grapefruit and lemon leaving a pleasant aroma behind.</p>
                    <h3>Features</h3>
                    <p>
                        <ul>
                            <li>Alcohol and Solvent free </li>
                            <li>Gentle on hands and silicone surfaces </li>
                            <li>Ready to use </li>
                            <li>100% cotton wipes </li>
                            <li>High absorbancy level</li>
                            <li>Natural ingredients with grapefruit and lemon:  </li>
                        </ul>
                    </p>
                    Water, Glycerin, Propylene Glycol, Chamomilla Recutita Flower Extract, Aloe Barbadensis Flower Extract, Citrus Paradisi Fruit Extract, Tocopheryl Acetate, Polyaminopropyl Biguanide, Phenoxyethanol, Citric Acid, PEG-40 Hydrogenated Castor Oil, Fragrance. 
                </div>
            }
            {this.props.objID=="childwipes" &&
                <div className="small-description">
                    <p>12 sachets with 10 pure cotton wipes each high quality 100% pure cotton wipes with high absorbency level.</p>
                    <p>Pürdoux™ Hand and Face Wipes for Babies and Children are enriched with natural chamomile & aloe vera plant extracts for gentle cleaning of baby's hands, face and diaper areas. Our children wipes are made with ultra-soft, 100% pure cotton to nourish sooth and moisturize your baby's delicate skin. Made without the use of harmful chemicals such as alcohol, latex, chlorine, artificial colors or scents.</p>
                    <h3>Features</h3>
                    <p>
                        <ul>
                            <li>Alcohol and Solvent free </li>
                            <li>Gentle on hands and silicone surfaces </li>
                            <li>Ready to use </li>
                            <li>100% cotton wipes </li>
                            <li>High absorbancy level</li>
                            <li>Natural ingredients: </li>
                        </ul>
                    </p>
                    Aqua, Propylene Glycol, Chamomilla Recutita Flower Extract, Phenoxyethanol, Cocamidopropyl Betaine, Potassium Sorbate, Aloe Barbadensis Leaf Extract, Alpha-Bisabolol, Citric Acid. 
                </div>
            }
            {this.props.objID=="unscentedsoap" &&
                <div className="small-description">
                    <p>Pürdoux™ CPAP Mask & Hose Soaps are made without the use of harmful chemicals and are designed to clean silicone surfaces while preserving the softness of your CPAP Mask & Hoses.</p>
                    <p>Our green tea and mint soap has a very mild scent to help neutralize odors of the silicone.</p>
                    <h3>Features</h3>
                    <p>
                        <ul>
                            <li>Free of alcohol, bleach, conditioners, moisturizers & latex  </li>
                            <li>Gentle on hands and silicone surfaces </li>
                            <li>Removes body oils from surfaces </li>
                            <li>Neutralizes odors </li>
                            <li>Natural ingredients with green tea and mint </li>
                            <li>250 ml bottle</li>
                        </ul>
                    </p>
                </div>
            }
            {this.props.objID=="scentedsoap" &&
                <div className="small-description">
                    <p>Pürdoux™ CPAP Mask & Hose Soaps are made without the use of harmful chemicals and are designed to clean silicone surfaces while preserving the softness of your CPAP Mask & Hoses.</p>
                    <p>Our grapefruit & lemon citrus soap has a very pleasant scent to help neutralize odors of the silicone.</p>
                    <h3>Features</h3>
                    <p>
                        <ul>
                            <li>Free of alcohol, bleach, conditioners, moisturizers & latex  </li>
                            <li>Gentle on hands and silicone surfaces </li>
                            <li>Removes body oils from surfaces </li>
                            <li>Neutralizes odors </li>
                            <li>Natural ingredients with green tea and mint </li>
                            <li>250 ml bottle</li>
                        </ul>
                    </p>
                </div>
            }
            {this.props.objID=="twoinonebrush" &&
                <div className="small-description">
                    <p>Anytime moisture sits in a confined space, you risk the chance of growing mold or algae. This multipurpose CPAP Hose Brush is the ideal cleaner for CPAP tubes.</p>
                    <p>Made with soft nylon bristles that are flexible to fit both slim & standard hoses. It includes a cylindrical shaped brush for scrubbing and a micro fiber cloth tip for drying. The sturdy carbon fiber stem comes in 6 parts which can be easily assembled and taken apart for easy convenient storing. With the 2 in 1 CPAP Hose Brush you can thoroughly clean and dry your hose in minutes.</p>
                    <h3>Features</h3>
                    <p>
                        <ul>
                            <li>Brush made with soft nylon bristles </li>
                            <li>Includes 2 micro fiber cloth tips for drying </li>
                            <li>Fits Slim and Standard hoses </li>
                            <li>Carbon fiber stem</li>
                            <li>Easy Storage </li>
                        </ul>
                    </p>
                </div>
            }
            {this.props.objID=="hosebrush" &&
                <div className="small-description">
                    <p>The CPAP Hose Brush is designed to gently clean your CPAP tubing to prevent moisture build up and the risk of growing mold or algae. Made with soft nylon bristles that are flexible to fit slim or standard hoses.</p>
                    <h3>Features</h3>
                    <p>
                        <ul>
                            <li>Brush made with soft nylon bristles </li>
                            <li>Fits Slim and Standard hoses </li>
                            <li>Easy grip handle </li>
                        </ul>
                    </p>
                </div>
            }
            {this.props.objID=="levata" &&
                <div className="small-description">
                    <p>Levätä is a 20 lbs high quality weighted blanket, designed to softly and comfortably reduce stress while enhancing sleep quality. </p>
                    <img src={newTwenty} width="30%"/>
                </div>
            }
            {this.props.objID=="chinstrap" &&
                <div className="small-description">
                    <p>Introducing our high quality, comfortable and easy to use chin strap to improve PAP therapy and reduce snoring.</p>
                </div>
            }
            {this.props.objID=="gelpillow" &&
                <div className="small-description">
                    <p>
                    <img className="smallimg2" src={require("../../img/media/best-in-rest/cpap-memory-foam-pillow-with-cooling-gel/icon-spine.png").default}/>
                    Memory foam provides firmness and support, while the gel ensures coolness and comfort. This premium quality reversible memory foam pillow with cooling gel is made specifically for CPAP users. It helps reduce mask leaks while introducing a new level of comfort for the user. 
                    </p>
                </div>
            }
            {this.props.objID=="pillow" &&
                <div className="small-description">
                    <p>
                        Adjusting the way your body is aligned while sleeping can make a huge impact towards getting a good night's rest. Our <b>MEMORY FOAM CPAP PILLOW WITH HERBAL INFUSION</b> is our promise and contribution to improving your sleep and CPAP experience.
                    </p>
                    <p>
                    This premium quality <b>aromatherapy</b> reversible Memory Foam CPAP Pillow not only reduces mask leaks but also introduces a new level of comfort by incorporating <b>aromatherapy</b> within a pillow. CPAP users often face a dilemma: CPAP machine is working well, the mask fits great, but the sleep quality may remain poor. What many CPAP users forget to take into consideration, is the right choice of pillow. No matter how well the CPAP therapy is set, achieving a deep relaxing sleep without the right pillow may seem very difficult task. 
                    </p>
                    <p>
                    Best in Rest™ <b>new patented revolutionary technology of herbal infusion of memory</b> foam helps CPAP users achieve a deep restful sleep like no CPAP pillow was able to do before. 
                    </p>
                </div>
            }
            {this.props.objID=="childpillow" &&
                <div className="small-description">
                    <p>Properly adjusting the way your body is aligned while sleeping can make a huge impact towards getting a good night's rest. The Best in Rest™ premium quality Memory Foam pillow helps children achieve a deep relaxing sleep like no other pillow was able to do before.</p>
                </div>
            }
            {this.props.objID=="eyemask" &&
                <div className="small-description">
                    <p>Treat yourself to complete luxury with this Memory Foam Anti-Fatigue Sleep Mask. Designed with your comfort in mind, it incorporates hot and cold gel inserts and includes earplugs for uninterrupted sleep.</p>
                </div>
            }
            {/*this.props.objID=="belt" &&
                <div className="small-description">
                    <p>This revolutionary belt serves as a sleep trainer for positional snoring and apnea.</p>
                    <p>The anti-snoring belt continuously monitors your position throughout the night and vibrates whenever you are on your back. This alerts you to move onto your side which will prevent further snoring and positional apneas.</p>
                </div>*/
            }
            {this.props.objID=="seatcushion" &&
                <div>
                    <p>Memory Foam Orthopedic Seat Cushion with cooling gel.</p>
                </div>
            }
            {this.props.objID=="hoselift" &&
                <div className="small-description">
                    <p>The Best in Rest Hose Lift is a support system that elevates and securely holds the CPAP hose allowing the user to move freely and achieve a more comfortable sleep. No assembly required.</p>
                    
                </div>
            }
            {this.props.objID=="adjustablememorypillow" &&
                <div className="small-description">
                    <p>Adjusting the way your body is aligned while sleeping can make a huge impact towards getting a good night’s rest. This premium quality reversible 2 in 1 pillow incorporates Best in Rest™ new patented revolutionary technology of herbal infusion memory foam that helps users achieve a deep relaxing sleep like no other pillow was able to do before.</p>
                    
                </div>
            }
            {this.props.objID=="dustcover" &&
                <div className="small-description">
                    <p>Shield your CPAP device, hose and mask with our comfortable soft suede fabric cover to keep it out of sight and dust free. Additionally, the anti-slip protector mat can help avoid the machine being pulled off the nightstand when moving and turning in bed.</p>
                </div>
            }
            {this.props.objID=="cpapfilters" &&
                <div className="small-description">
                    <p>The PürdouxTM high quality CPAP filters fits perfectly and precisely into your device to block dust and pollen. Keep the air you breathe in with your PAP devices clean and healthy to get the best results from your therapy.</p>
                </div>
            }
            {this.props.objID=="disinfectingwipes" &&
                <div className="small-description">
                    <p>Available in Canada Only</p>
                    <p>A fresh natural fragrance enriched with Aloe Vera</p>
                    <p>Active Ingredient: Benzalkonium chloride 0.13% (w/w)</p>
                    <p>Available Sizes: 80 wipe Canister, 130 wipe Canister, 400 wipe Bucket, 400 wipe Refill Bag, 800 wipe Bucket, 800 Refill Bag</p>
                </div>
            }
            {this.props.objID=="sanitizingwipes" &&
                <div className="small-description">
                    <p>Available in USA Only</p>
                    <p>A fresh natural fragrance enriched with Aloe Vera</p>
                    <p>Active Ingredient: Benzalkonium chloride 0.13% (w/w)</p>
                    <p>Available Sizes: 80 wipe Canister, 130 wipe Canister, 400 wipe Bucket, 400 wipe Refill Bag</p>
                </div>
            }
        </div>
    );
  }
}