import React from 'react';
import './Footer.css';
import {
    BrowserRouter as Router,
    Link
  } from 'react-router-dom';
  

export default class NavBar extends React.Component {
  constructor(){
    super();
  }
  render(){
    return (
      <div className="footer">
        
        <div className="footer-container">
        <Link to="/contact-us">
            <div className="footer-ribbon">Get in Touch</div>
        </Link>
            <div className="footer-row">
                <div className="column">
                    <div className="contact-details">
                        <h4>Contact Us</h4>
                        <ul>
							<li><p> <strong>Canada and USA: 1-888-400-8987</strong> </p></li>	
                            <li><p> <strong>Australia: 1-300-146-358</strong> </p></li>	
                            <li><p> <strong>EU: 44-800-060-8576</strong> </p></li>	
                            <li><p> <strong>Other Regions: +1-647-846-7700</strong> </p></li>	
                            <li><p> <strong>Email:</strong> <a href="mailto:info@choiceonemedical.com">info@choiceonemedical.com</a></p></li>
   						</ul>
                    </div>
                </div>
                <div className="column">
                    <div className="quick-links">
                        <h4>Quick Links</h4>
                        <ul>
                            <Link to="/products/purdoux">
                                <li>Purdoux</li>
                            </Link>
                            <Link to="/products/medistrom">
                                <li>Medistrom</li>
                            </Link>
                            <Link to="/products/best-in-rest">
                                <li>Best In Rest</li>
                            </Link>
                            <Link to="/community-support">
                                <li>Community Support</li>
                            </Link>
                            <Link to="/about-us">
                                <li>About Us</li>
                            </Link>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-copyright">
            <div className="footer-container">
                <div className="row">
                    <div className="column">
                        <p>&copy; Copyright {new Date().getFullYear()} ChoiceOneMedical. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
  }
}