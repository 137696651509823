import React from 'react';
import PageHeader from "./PageHeader";
import './ProductPage.css';
import './AboutUs.css';
import ImageModal from "./ImageModal";

export default class CommunitySupport extends React.Component {
  render(){

    return (
      <div className="product-page">
        <PageHeader title={"Community Support"}/>
        <div className="product-page-container">
          <div className="row">
            <div className="col-two">
              <div className = "charity">
                  <p><img className="smallimg" width="19%" src={require("../img/community/MLSE_logo.png").default}/>
                    <h3>MLSE</h3>
                    <br/>Keeping our community safe, while they support our Toronto teams!</p>
                    <br/>
                <ImageModal imgSrc={require("../img/community/MLSE.JPG").default}/>
                <ImageModal imgSrc={require("../img/community/MLSE2.JPG").default}/>
              </div>
            </div>
            <div className="col-two">
                <div className = "charity">
                <p><img className="smallimg"  width="19%" src={require("../img/community/cp24_logo.jpg").default}/>
                  <h3>CP24 Chum Christmas Wish Program</h3>
                  Donating to children in need.</p>
                  <br/>
                  <ImageModal imgSrc={require("../img/community/CP24-Chum-Christmas-Wish.jpg").default}/>
                  <ImageModal imgSrc={require("../img/community/CP24 Chum Christmas Wish Program.JPG").default}/>
                </div>
            </div>
            
            
          </div>
          <br/>
          <br/>

          <div className="row">
            <div className="col-two">
                  <div className = "charity">
                  <p><img className="smallimg"  width="19%" src={require("../img/community/powerbev-logo-sized.png").default}/>
                    <h3>Powerbev</h3>
                    Showing our support and appreciation to our clients around the holidays</p>
                    <br/>
                    <ImageModal imgSrc={require("../img/community/Powerbev.JPG").default}/>
                  </div>
              </div>
              <div className="col-two">
                <div className = "charity">
                    <p><img className="smallimg" width="19%" src={require("../img/community/Treat Accessibly Logo.jpg").default}/>
                      <h3>Treat Accessibly</h3>
                      Donation to Treat Accessibly, a foundation that promotes accessible and inclusive change, and Halloween without barriers.</p>
                      <br/>
                  <ImageModal imgSrc={require("../img/community/Treat Accessibly Donation.jpg").default}/>
                  
                </div>
              </div>
          </div>
          <br/>
          <br/>

          <div className="row">
          <div className="col-two">
              <div className = "charity">
                <p><img className="smallimg" width="17.4%" src={require("../img/community/Covenant_House-logo.png").default}/>
                  <h3>Covenant House</h3>
                  Donating to the largest agency in Canada serving youth who are homeless, trafficked or at risk. </p>
                  <br/>
                <ImageModal imgSrc={require("../img/community/Covenant-House-Toronto.jpg").default}/>
              </div>
            </div>
            <div className="col-two">
              <div className = "charity">
                  <p><img className="smallimg" width="19%" src={require("../img/community/good-shepherd-ministries.jpg").default}/>
                    <h3>Good Shepherd Ministries</h3>
                    Donating to our community members who are homeless and at-risk.</p>
                    <br/>
                <ImageModal imgSrc={require("../img/community/Good-Shepherd.jpg").default}/>
                
              </div>
            </div>
            
          </div>
        </div>
      </div>
    );
  }
}