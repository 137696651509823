import React from 'react';
import PageHeader from "./PageHeader";
import './BrandPage.css';
import logo from "../img/logos/logo-1.png";
import VideoRow from "./products/VideoRow";
import AlbumRow from "./products/AlbumRow";

export default class MediaMedistrom extends React.Component {
  constructor(){
    super();
  }
  render(){
    return (
      <div className="product-page">
        <PageHeader title={"Our Products"}/>
        <div className="product-page-container">
          <div className="brand">
            <img src={logo}/>
          </div>
          <VideoRow title = 'Pilot Lite Internal Training Video (English only)' url='https://youtu.be/G4pmzfEcaW8'/>
          <VideoRow title = 'Pilot Lite Internal Training Video (English with French Subtitles)' url='https://youtu.be/1_9eN5QOzTM'/>
          <VideoRow title = 'Pilot-12/24 Plus CPAP Backup Power Supply Video' url='https://youtu.be/o8nZn51E_Eo'/>
          <VideoRow title = 'Pilot-12 Plus Video Demonstration' url='https://youtu.be/zANHH0Rd7Yg'/>
          <VideoRow title = 'Pilot-24 Plus Video Demonstration' url='https://youtu.be/ICndfR6rTd0'/>
          <AlbumRow title = 'Pilot-12 Lite CPAP Backup Power Supply' 
          files={[
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-box.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-cable-for-phillips-respironics-dreamstation-s60.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-lite-car-charger.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-lite-conected-to-phillips-respironics-dreamstation-pap-device.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-lite-connected-to-HDM-Z1-AC-adapter-via-the-short-charging-adapter-cable.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-lite-connected-to-HDM-Z1-AC-adapter-via-the-short-charging-adapter-cable-2.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-lite-connected-to-phillips-respironics-ac-adapter-and-the-phillips-respironics-dreamstation-pap.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-lite-connected-to-phillips-respironics-dreamstation-ac-adapter.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-lite-mixed-cable-kit.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-lite-with-included-cable.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-24-lite-and-solar-panel.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-24-lite-and-solar-panel-1.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-24-lite-car-charger.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-24-lite-carry-sleeve.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-24-lite-carry-sleeve-1.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-24-lite-connected-to-smartphone.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-24-lite-control-panel.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-24-lite-control-panel-1.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-24-lite-in-carry-sleeve.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-24-lite-on-carry-sleeve.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-24-lite-plugged-with-car-charger.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-24-lite-solar-panel.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-24-lite-vs-plus.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-24-lite-vs-plus-1.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-24-lite-vs-plus-2.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/DreamStation 2 BACKUP.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/DreamStation 2 DC OUT CABLE.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/DreamStation 2 DC OUT CABLE.jpg').default
          ]}/>
          <AlbumRow title = 'Pilot-24 Lite CPAP Backup Power Supply'
          files={[
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-box.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-cable-for-resmed-airmini.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-cable-for-resmed-airsense-10.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-cable-tip-for-resmed-airmini.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-lite-connected-to-ac-adapter-and-resmed-airsense-10-pap-device.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-lite-connected-to-ac-adapter-and-resmed-airsense-10-pap-device-2.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-lite-connected-to-dreamstation-go.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-lite-connected-to-resmed-airmini-ac-adapter-with-adapter-cable.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-lite-connected-to-resmed-airmini-ac-adapter-with-adapter-cable-2.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-lite-connected-to-resmed-airsense-10-pap-device.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-lite-connected-to-resmed-mini.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-lite-conneted-to-resmed-airsense-10-ac-adapter.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-lite-with-included-cables.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/AirSense 11 BACKUP.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/AirSense 11 CABLE KIT.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/AirSense 11 TRAVEL.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/Lowenstein DC Cable & Charging Adapter.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/Lowenstein Prisma P24 no cable.jpg').default
          ]}/>
          <AlbumRow title = 'Pilot-12/24 Lite Cables and Accessories '
          files={[
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-lite-ac-power-adapter.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-lite-apex-medical-kit.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-resironics-dreamstation-go-and-luna-kit.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-lite-s9-kit.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-lite-mixed-cable-kit.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/large/pilot-12-lite-car-charger.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/Solar Panel - 8 panel.png').default,
            require('../img/media/medistrom/n/pilot12-lite/Solar Panel Flat.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/IMG_3125.jpg').default,
            require('../img/media/medistrom/n/pilot12-lite/Solar Panel Connection.jpg').default
          ]}/>
          <AlbumRow title = 'Pilot-12 Plus CPAP Backup Power Supply' 
          files={[
            require('../img/media/medistrom/p12-all.jpg').default,
            require('../img/media/medistrom/medistrom-pilot12-dreamstation.jpg').default,
            require('../img/media/medistrom/medistrom-pilot-12-dreamstation-lg.jpg').default,
            require('../img/media/medistrom/medistrom-pilot-12-dreamstation1-lg.jpg').default,
            require('../img/media/medistrom/medistrom-pilot-12-cellphone-lg.jpg').default,
            require('../img/media/medistrom/p12-all1.jpg').default,
            require('../img/media/medistrom/p12-all2.jpg').default,
            require('../img/media/medistrom/p12-box.jpg').default,
            require('../img/media/medistrom/p12-box1.jpg').default,
            require('../img/media/medistrom/pilot12-1-web.jpg').default,
            require('../img/media/medistrom/pilot12-2-web.jpg').default,
            require('../img/media/medistrom/pilot12-3-web.jpg').default,
            require('../img/media/medistrom/pilot12-4-web.jpg').default,
            require('../img/media/medistrom/pilot12-5-web.jpg').default,
            require('../img/media/medistrom/pilot12-6-web.jpg').default,
            require('../img/media/medistrom/pilot12-7-web.jpg').default,
            require('../img/media/medistrom/p12-remstar-cord-web.jpg').default,
            require('../img/media/medistrom/p12-remstar-web.jpg').default
          ]}/>
          <AlbumRow title = 'Pilot-24 Plus CPAP Backup Power Supply' 
          files={[
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-box.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-cable-for-resmed-airmini.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-cable-for-resmed-airsense-10.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-cable-tip-for-resmed-airmini.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-lite-connected-to-ac-adapter-and-resmed-airsense-10-pap-device.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-lite-connected-to-ac-adapter-and-resmed-airsense-10-pap-device-2.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-lite-connected-to-dreamstation-go.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-lite-connected-to-resmed-airmini-ac-adapter-with-adapter-cable.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-lite-connected-to-resmed-airmini-ac-adapter-with-adapter-cable-2.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-lite-connected-to-resmed-airsense-10-pap-device.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-lite-connected-to-resmed-mini.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-lite-conneted-to-resmed-airsense-10-ac-adapter.jpg').default,
            require('../img/media/medistrom/n/pilot24-lite/large/pilot-24-lite-with-included-cables.jpg').default
          ]}/>
          <AlbumRow title = 'Co-Pilot-12' 
          files={[
            require('../img/media/medistrom/co-pilot12-web.jpg').default,
            require('../img/media/medistrom/co-pilot12-web1.jpg').default,
            require('../img/media/medistrom/co-pilot12-web2.jpg').default,
            require('../img/media/medistrom/co-pilot12-web3.jpg').default,
            require('../img/media/medistrom/co-pilot-web.jpg').default,
            require('../img/media/medistrom/co-pilot-web1.jpg').default,
            require('../img/media/medistrom/co-pilot-web2.jpg').default,
            require('../img/media/medistrom/co-pilot-web3.jpg').default,
            require('../img/media/medistrom/co-pilot-web4.jpg').default,
            require('../img/media/medistrom/co-pilot-web5.jpg').default,
            require('../img/media/medistrom/co-pilot-web6.jpg').default,
            require('../img/media/medistrom/co-pilot-web7.jpg').default,
            require('../img/media/medistrom/co-pilot-web8.jpg').default,
          ]}/>
          <AlbumRow title = 'Co-Pilot-24'
          files={[
            require('../img/media/medistrom/co-pilot24-web.jpg').default,
            require('../img/media/medistrom/co-pilot24-web1.jpg').default,
            require('../img/media/medistrom/co-pilot24-web2.jpg').default,
            require('../img/media/medistrom/co-pilot24-web3.jpg').default,
            require('../img/media/medistrom/co-pilot24-web4.jpg').default,
            require('../img/media/medistrom/co-pilot24-web5.jpg').default,
            require('../img/media/medistrom/co-pilot-web.jpg').default,
            require('../img/media/medistrom/co-pilot-web1.jpg').default,
            require('../img/media/medistrom/co-pilot-web2.jpg').default,
            require('../img/media/medistrom/co-pilot-web3.jpg').default,
            require('../img/media/medistrom/co-pilot-web4.jpg').default,
            require('../img/media/medistrom/co-pilot-web5.jpg').default,
            require('../img/media/medistrom/co-pilot-web7.jpg').default,
            require('../img/media/medistrom/co-pilot-web8.jpg').default,
          ]}/>
          <AlbumRow title = 'Pilot-12 Car Charger' 
          files={[
            require('../img/media/medistrom/car-charger-p12-1.jpg').default,
            require('../img/media/medistrom/car-charger-p12-2.jpg').default,
            require('../img/media/medistrom/car-charger-p12-3.jpg').default,
            require('../img/media/medistrom/car-charger-p12-4.jpg').default,
            require('../img/media/medistrom/car-charger-p12-5.jpg').default
          ]}/>
          <AlbumRow title = 'Pilot-24 Car Charger'
          files={[
            require('../img/media/medistrom/car-charger-p24-1.jpg').default,
            require('../img/media/medistrom/car-charger-p24-2.jpg').default,
            require('../img/media/medistrom/car-charger-p24-3.jpg').default,
            require('../img/media/medistrom/car-charger-p24-4.jpg').default,
            require('../img/media/medistrom/car-charger-p24-5.jpg').default,
            require('../img/media/medistrom/car-charger-p24-6.jpg').default
          ]}/>
          <AlbumRow title = 'Cable Kit For Pilot-24'
          files={[
            require('../img/media/medistrom/cable-kit-for-pilot-24.jpg').default,
            require('../img/media/medistrom/cable-kit-for-pilot-24-box-1.jpg').default,
            require('../img/media/medistrom/pilot-24-with-airmini-1.jpg').default,
            require('../img/media/medistrom/pilot-24-with-airmini-2.jpg').default,
            require('../img/media/medistrom/pilot-24-with-airmini-4.jpg').default,
            require('../img/media/medistrom/pilot-24-with-airmini-5.jpg').default,
            require('../img/media/medistrom/pilot-24-with-airmini-6.jpg').default,
          ]}/>
        </div>
      </div>
      
    );
  }
}