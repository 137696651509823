import React from 'react';
import ReactPlayer from "react-player"
import '../common.css';

export default class VideoRow extends React.Component {
  constructor(){
    super();
  }
  render(){
    return (
      <div className="row">
          <div className="row">
            <h3 className="media-row-title" style={{marginLeft: '30px'}}>{this.props.title}</h3>
          </div>
          <div className="row">
            <ReactPlayer url={this.props.url} style={{marginLeft: '30px'}} controls='true'/>
          </div>
          <p style={{width:'100%'}}><hr/></p>
      </div>
    );
  }
}