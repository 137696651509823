import React from 'react';
import logo_one from "../img/logos/media-best-in-rest-logo1.jpg";
import logo_two from "../img/logos/media-medistrom-logo.jpg";
import logo_three from "../img/logos/media-purdoux-logo.jpg";
import PageHeader from "./PageHeader";
import './Products.css';
import './common.css';
import {
  BrowserRouter as Router,
  Link
} from 'react-router-dom';


export default class Products extends React.Component {
  constructor(){
    super();
  }
  render(){
    return (
      <div className="products">    
        <PageHeader title={"Our Products"}/>
        <div className="info">
          <h2>Welcome</h2>
          <div className="info-text">
          At Choice One Medical we strive to provide high quality medical products to our clients following the latest innovations and modern designs with cutting edge technology that brings the healthcare to the highest standards. 
          </div>
          <div className="info-text">To Become Our Dealer, please contact us: </div>
          <div className="info-text">
          <b>North America</b> 1-888-400-8987, <b>Australia</b> 1-300-146-358, <b>EU</b> 44-800-060-8576, <b>Other regions</b> +1-647-846-7700, or <b>email</b> <a href="mailto:sales@choiceonemedical.com">sales@choiceonemedical.com</a>
          </div>
        </div>
        <div className = "our-brands">
          <hr/>
          <h2>Our Brands</h2>
          <Link to="/products/best-in-rest">
          <div className="colthree"><img src={logo_one} style={{width:'60%'}}/></div>
          </Link>
          <Link to="/products/medistrom">
          <div className="colthree"><img src={logo_two}  style={{width:'60%'}}/></div>
          </Link>
          <Link to="/products/purdoux">
          <div className="colthree"><img src={logo_three}  style={{width:'60%'}}/></div>
          </Link>
        </div>
      </div>
    );
  }
}