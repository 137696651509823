import React from 'react';
import PageHeader from "./PageHeader";
import './BrandPage.css';
import logo from "../img/logos/logo-3.png";
import AlbumRow from "./products/AlbumRow";

export default class MediaPurdoux extends React.Component {
  constructor(){
    super();
  }
  render(){
    return (
      <div className="product-page">
        <PageHeader title={"Our Products"}/>
        <div className="product-page-container">
          <div className="brand">
            <img src={logo}/>
          </div>
          <AlbumRow title = 'CPAP Filters'
          files = {[
            require('../img/media/purdoux/cpapfilters/2x Airmini 15 pk.jpg').default,
            require('../img/media/purdoux/cpapfilters/2x S9S10 15 pk.jpg').default,
            require('../img/media/purdoux/cpapfilters/3x Airmini 3 pk.jpg').default,
            require('../img/media/purdoux/cpapfilters/3x Airmini 15 pk.jpg').default,
            require('../img/media/purdoux/cpapfilters/3x S9S10 15 pk.jpg').default,
            require('../img/media/purdoux/cpapfilters/4x Airmini 3 pk.jpg').default,
            require('../img/media/purdoux/cpapfilters/4x S9S10 3 pk.jpg').default,
            require('../img/media/purdoux/cpapfilters/Filters - AirMini - 3 pk.jpg').default,
            require('../img/media/purdoux/cpapfilters/Filters - AirMini - 15 pk.jpg').default,
            require('../img/media/purdoux/cpapfilters/Filters - AirSense10S9 - 3 pk.jpg').default,
            require('../img/media/purdoux/cpapfilters/Filters - AirSense10S9 - 15 pk.jpg').default,
            require('../img/media/purdoux/cpapfilters/Filters - DS Disposable - 5 pk.jpg').default,
            require('../img/media/purdoux/cpapfilters/Filters - DS Reusable - 2 pk.jpg').default,
            require('../img/media/purdoux/cpapfilters/Filters - DS Reusable - 2 pk (front).jpg').default,
            require('../img/media/purdoux/cpapfilters/S9S10 3 x 3 pack.jpg').default,
            require('../img/media/purdoux/cpapfilters/S11-6pk-copy.jpg').default,
            require('../img/media/purdoux/cpapfilters/S11-15pk-copy.jpg').default,
          ]}/>
          <AlbumRow title = 'CPAP Dust Cover & Protector Mat'
          files = {[
            require('../img/media/purdoux/cpap_cover_mat/Machine Mask & Tubing.jpg').default,
            require('../img/media/purdoux/cpap_cover_mat/Mat & Cover.jpg').default,
            require('../img/media/purdoux/cpap_cover_mat/Mat.jpg').default,
            require('../img/media/purdoux/cpap_cover_mat/Packaging.jpg').default,
          ]}/>
          <AlbumRow title = 'Sanitizing Wipes'
          files = {[
            require('../img/media/purdoux/sanitizing_wipes/80-and-130.jpg').default,
            require('../img/media/purdoux/sanitizing_wipes/80---Foil-Cap.jpg').default,
            require('../img/media/purdoux/sanitizing_wipes/80---Front.jpg').default,
            require('../img/media/purdoux/sanitizing_wipes/80---Open-Lid.jpg').default,
            require('../img/media/purdoux/sanitizing_wipes/130---Foil-Cap.jpg').default,
            require('../img/media/purdoux/sanitizing_wipes/130---Front.jpg').default,
            require('../img/media/purdoux/sanitizing_wipes/130---Open-Lid.jpg').default,
          ]}/>
          <AlbumRow title = 'Disinfecting Wipes'
          files={[
            require('../img/media/purdoux/disinfecting_wipes/Disinfecting Wipes 80 130.jpg').default,
            require('../img/media/purdoux/disinfecting_wipes/Disinfecting Wipes Hand Sanitizer.jpg').default,
            require('../img/media/purdoux/disinfecting_wipes/80 Back 1.jpg').default,
            require('../img/media/purdoux/disinfecting_wipes/80 Back 2.jpg').default,
            require('../img/media/purdoux/disinfecting_wipes/80 Foil Top.jpg').default,
            require('../img/media/purdoux/disinfecting_wipes/80 Open Lid.jpg').default,
            require('../img/media/purdoux/disinfecting_wipes/80 Open Top.jpg').default,
            require('../img/media/purdoux/disinfecting_wipes/80.jpg').default,
            require('../img/media/purdoux/disinfecting_wipes/130 Foil Top.jpg').default,
            require('../img/media/purdoux/disinfecting_wipes/130 Open Lid.jpg').default,
            require('../img/media/purdoux/disinfecting_wipes/130 Open Top.jpg').default,
            require('../img/media/purdoux/disinfecting_wipes/130.jpg').default
          ]}/>
          <AlbumRow title = 'Purdoux™ Wipes'
          files={[
            require('../img/media/purdoux/wipes/purdoux_aloe-can.jpg').default,
            require('../img/media/purdoux/wipes/purdoux_aloe-travel.jpg').default,
            require('../img/media/purdoux/wipes/purdoux_lemon-aloecan.jpg').default,
            require('../img/media/purdoux/wipes/purdoux_lemon-can-open.jpg').default,
            require('../img/media/purdoux/wipes/purdoux_aloe-can-1.jpg').default,
            require('../img/media/purdoux/wipes/purdoux_aloe-travel-1.jpg').default,
            require('../img/media/purdoux/wipes/purdoux_lemon-can.jpg').default,
            require('../img/media/purdoux/wipes/purdoux-travel-box-grapefruit.jpg').default,
          ]}/>
          <AlbumRow title = 'Hand Sanitizer with Aloe Vera'
          files={[
            require('../img/media/purdoux/hand_sanitizer/hand_sanitizer_1l.jpg').default,
            require('../img/media/purdoux/hand_sanitizer/hand_sanitizer_500ml.jpg').default,
            require('../img/media/purdoux/hand_sanitizer/hand_sanitizer_60ml.jpg').default,
            require('../img/media/purdoux/hand_sanitizer/hand_sanitizer1.jpg').default,
            require('../img/media/purdoux/hand_sanitizer/hand_sanitizer4.jpg').default
          ]}/>
          <AlbumRow title = 'AirMini Filters'
          files={[
            require('../img/media/purdoux/airmini_filters/Filters - AirMini - 3 pk.jpg').default,
            require('../img/media/purdoux/airmini_filters/Filters - AirMini - 15 pk.jpg').default
          ]}/>
          <AlbumRow title = 'AirSense Filters'
          files={[
            require('../img/media/purdoux/airsense_filters/Filters - AirSense10.S9 - 3 pk UF.jpg').default,
            require('../img/media/purdoux/airsense_filters/Filters - AirSense10S9 - 3 pk.jpg').default,
            require('../img/media/purdoux/airsense_filters/Filters - AirSense10S9 - 15 pk.jpg').default
          ]}/>
          <AlbumRow title = 'DreamStation Filters'
          files={[
            require('../img/media/purdoux/dreamstation_filters/Filters - DS Disposable - 5 pk.jpg').default,
            require('../img/media/purdoux/dreamstation_filters/Filters - DS Reusable - 2 pk (front).jpg').default
          ]}/>
          
          <AlbumRow title = 'CPAP Hose Standard 22mm'
          files={[
            require('../img/media/purdoux/hose_standard/Standard-Hose.jpg').default,
            require('../img/media/purdoux/hose_standard/Standard-Packaging-back.jpg').default,
            require('../img/media/purdoux/hose_standard/Standard-Packaging-back-w-Hose.jpg').default,
            require('../img/media/purdoux/hose_standard/Standard-Packaging-w-Hose.jpg').default,
          ]}/>
          <AlbumRow title = 'CPAP Hose PürSlim 15mm'
          files={[
            require('../img/media/purdoux/hose_purslim/slim-4.jpg').default,
            require('../img/media/purdoux/hose_purslim/Slim-Hose.jpg').default,
            require('../img/media/purdoux/hose_purslim/Slim-Packaging.jpg').default,
            require('../img/media/purdoux/hose_purslim/Slim-Packaging-back.jpg').default,
            require('../img/media/purdoux/hose_purslim/Slim-Packaging-back-w-Hose.jpg').default
          ]}/>
          
          <AlbumRow title = 'Purdoux™ Hand & Face Wipes for Babies & Children'
          files={[
            require('../img/media/purdoux/child_wipes/hand-and-face-wipes-for-children.jpg').default,
            require('../img/media/purdoux/child_wipes/hand-and-face-wipes-for-children-drawing.jpg').default
          ]}/>
          <AlbumRow title = 'Purdoux™ CPAP Mask & Hose Soaps'
          files={[
            require('../img/media/purdoux/soaps/purdoux-CPAP-Mask-&-Hose-Soap-Citrus.jpg').default,
            require('../img/media/purdoux/soaps/purdoux-CPAP-Mask-&-Hose-Soap-GreenTea-&-Mint.jpg').default
          ]}/>
          <AlbumRow title = 'Purdoux™ CPAP Hose Brushes'
          files={[
            require('../img/media/purdoux/hose_brushes/purdoux-2in1-CPAP-Hose-Brush.jpg').default,
            require('../img/media/purdoux/hose_brushes/purdoux-hose-brush.jpg').default
          ]}/>
          
        </div>
      </div>
      
    );
  }
}