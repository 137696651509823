import React from 'react';
import PageHeader from "./PageHeader";
import './ProductPage.css';
import './AboutUs.css';
import './common.css';
import ImageModal from "./ImageModal";
import logo_one from "../img/logos/media-best-in-rest-logo1.jpg";
import logo_two from "../img/logos/media-medistrom-logo.jpg";
import logo_three from "../img/logos/media-purdoux-logo.jpg";
import {
    BrowserRouter as Router,
    Link
  } from 'react-router-dom';

export default class MediaLibrary extends React.Component {
  render(){

    return (
      <div className="products">
        <PageHeader title={"Media Library"}/>
        <div className="info">
            <h2>Media Library</h2>
            <div className="info-text">Welcome to Choice One Medical's media library. You could download photos and other advertising materials of our brands clicking on images below or menu on the right.</div>
        </div>
        <div className = "our-brands">
          <hr/>
          <h2>Select Brand</h2>
          <Link to="/media-library/best-in-rest">
          <div className="colthree"><img src={logo_one} style={{width:'60%'}}/></div>
          </Link>
          <Link to="/media-library/medistrom">
          <div className="colthree"><img src={logo_two}  style={{width:'60%'}}/></div>
          </Link>
          <Link to="/media-library/purdoux">
          <div className="colthree"><img src={logo_three}  style={{width:'60%'}}/></div>
          </Link>
          <Link to = "/media-library/other"><h2 className="link">Other</h2></Link>
        </div>
      </div>
    );
  }
}