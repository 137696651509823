import React from 'react';
import './common.css';


export default class UnderConstruction extends React.Component {
  constructor(){
    super();
  }
  render(){
    const aboutUsTitle={
      fontSize: '2.6em',
      lineHeight: '44px',
      margin: '0 0 44px 0',
      color: '#0e0e0e',
      fontWeight: '200',
      letterSpacing:'-1px',
      fontFamily:'inherit',
      letterSpacing: '-1px'
    }
    return (
      <div className="page">
          <div className="about-us">
            <div className = "row">
              <h2 style={aboutUsTitle}>Under Construction...</h2>
            </div>
          </div>
      </div>
    );
  }
}