import React from 'react';
import data from '../data.json';
import Slider from "react-slick";
import PageHeader from "./PageHeader";
import logo_one from "../img/logos/logo-1.png";
import logo_two from "../img/logos/logo-2.png";
import logo_three from "../img/logos/logo-3.png";
import './ProductPage.css';
import SmallDescription from "./products/SmallDescription";
import LargeDescription from "./products/LargeDescription";


export default class ProductPage extends React.Component {
  constructor(){
    super();
  }

  render(){
    const bannersettings = {
      dots: true,
      fade: true,
      infinte: true,
      speed: 500,
      slidesToShow: 1,
      arrows: true,
      slidesToScroll: 1,
      className: "banner"
    }
    const str = this.props.match.params.value.toString()+"_obj";
    return (
      <div className="product-page">
        <PageHeader title={"Our Products"}/>
        <div className="product-page-container">
          <div className="row">
            <div className="coltwo" >
              <Slider {...bannersettings}>
                {data[str].images.map((slide)=>
                  <img key={str} src={require("../"+slide.toString()).default}/>
                  )}
              </Slider>
            </div>
            <div className="coltwo" >
                <div className="brand-pic">
                  {data[str].brand=="medistrom" &&
                    <img src={logo_one}/>
                  }
                  {data[str].brand=="best-in-rest" &&
                    <img src={logo_two}/>
                  }
                  {data[str].brand=="purdoux" &&
                    <img src={logo_three}/>
                  }
                </div>
                <br/>

                <h2>{data[str].title}</h2>
                <SmallDescription objID={data[str].url}/>
                <br/>
                <br/>
              </div>
            </div>
            <LargeDescription objID={data[str].url}/>
            <hr/>
            <div className="row">
                  <p className="become-dealer">To become our dealer, please contact us at (888) 400-8987.</p>
            </div>
        </div>

      </div>
    );
  }
}