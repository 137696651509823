import React from 'react';
import * as ReactBootStrap from "react-bootstrap";
import '../ProductPage.css';
import {
    Link
  } from "react-router-dom";

export default class LargeDescription extends React.Component {
  constructor(){
    super();
  }
  render(){
    return (
        <div>
            {this.props.objID=="pilot12lite" &&
                <div className="large-description">
                        <p>Introducing the new Pilot-12 Lite – revolutionary small Backup Power Supply/ Portable Travel Battery for Philips Respironics, DeVilbiss, and other 12V PAP Devices. The Pilot-12 Lite is compatible with: </p>
                        <ul>
                            <li><p>Phillips Respironics DreamStation 2*</p></li>
                            <li><p>Phillips Respironics DreamStation</p></li>
                            <li><p>Phillips Respironics System One S60</p></li>
                            <li><p>DeVilbiss IntelliPAP I and IntelliPAP II*</p></li>
                            <li><p>HDM Z1*</p></li>
                            <p><span style={{fontSize:"13px"}}>(*Cable Kits sold separately)</span></p>
                            </ul> 
                        <p>While using the Pilot-12 Lite as a permanent power backup device, you can rest assured that you won't be woken in the middle of the night to find that your CPAP machine is not running. If the power is interrupted at night, your CPAP machine will drive power from your Pilot-12 Lite without any interruption.</p>
                        <p>Using the Pilot-12 Lite as a Portable Travel CPAP Battery will help you receive CPAP therapy while traveling. This includes remote locations where mains power is not available in situations such as camping, road trips and during air travel.</p>
                        <p>The Pilot-12 Lite can also be used as a charging station for various electronic devices. With the built-in USB port it is capable of powering many handheld electronic devices including mobile phones, tablets and cameras.</p>

                        <div className="tabs-container">
                            <ReactBootStrap.Tabs defaultActiveKey="features" id="uncontrolled-tab-example">
                                <ReactBootStrap.Tab eventKey="features" title="Pilot-12 Lite Features">
                                    <div className="tab-container">
                                        <div className="row">
                                            <div className="pic-col">
                                                <img src={require("../../img/media/medistrom/n/pilot12-lite/pilot-12-lite-control-panel-508px.jpg").default}/>
                                            </div>
                                            <div className="col">
                                                <h3>Medistrom™ Pilot-12 Lite Features</h3>
                                                <ul>
                                                    <li><p>Can be used as a permanent backup power supply or a portable travel battery for common 12V PAP Devices</p>
                                                        <ul>
                                                            <li><p>Philips Respironics DreamStation</p></li>
                                                            <li><p>Philips Respironics System One S60</p></li>
                                                            <li><p>DeVilbiss IntelliPAP I and II*</p></li>
                                                            <li><p>HDM Z1* (*Cable Kits sold separately)</p></li>
                                                        </ul>
                                                    </li>
                                                    <li><p>Can be used as a Portable Charging Station for most handheld electronic devices such as phones, tablets, and camera</p></li>
                                                    <li><p>Features a built-in LED Flashlight</p></li>
                                                    <li><p>FAA Compliant</p></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </ReactBootStrap.Tab>
                                <ReactBootStrap.Tab eventKey="specs" title="Specifications">
                                    <div className="tab-container">
                                        <div className="row">
                                            <h3>Specifications</h3>
                                        </div>
                                        <div className="row">
                                            <ReactBootStrap.Table striped bordered className="spec-table">
                                                <tbody>
                                                    <tr>
                                                        <td width="22%">Capacity:</td>
                                                        <td>95Wh</td>
                                                    </tr>
                                                    <tr>
                                                        <td width="22%">Cell Type:</td>
                                                        <td>Industrial grade genuine LG rechargeable lithium ion cells</td>
                                                    </tr>
                                                    <tr>
                                                        <td width="22%">Rated Input: </td>
                                                        <td>12V, 60W (NORMAL), 80W (MAX)</td>
                                                    </tr>
                                                    <tr>
                                                        <td width="22%">Rated Output:</td>
                                                        <td>DC 12V, 6.67A</td>
                                                    </tr>
                                                    <tr>
                                                        <td width="22%">USB Out:</td>
                                                        <td>5V, 2.5A</td>
                                                    </tr>
                                                    <tr>
                                                        <td width="22%">Life Span:</td>
                                                        <td>Over 500 full charge/discharge cycles</td>
                                                    </tr>
                                                    <tr>
                                                        <td width="22%">Case:</td>
                                                        <td>Aluminium</td>
                                                    </tr>
                                                    <tr>
                                                        <td width="22%">Dimensions:</td>
                                                        <td>17cm x 9cm x 2cm (6.7" x 3.5" x 0.8")</td>
                                                    </tr>
                                                    <tr>
                                                        <td width="22%">Weight:</td>
                                                        <td>560g (1.3lbs)</td>
                                                    </tr>
                                                    <tr>
                                                        <td width="22%">Power Gauge:</td>
                                                        <td>4 stage Charge Level Indicator Lights</td>
                                                    </tr>
                                                    <tr>
                                                        <td width="22%">Recharge time:</td>
                                                        <td>2-3 hours via Respironics DreamStation AC Adapter (charging via other compatible adapters may take longer)</td>
                                                    </tr>
                                                </tbody>  
                                            </ReactBootStrap.Table>
                                        </div>
                                    </div>
                                </ReactBootStrap.Tab>
                                <ReactBootStrap.Tab eventKey="about" title="More about the product">
                                    <div className="tab-container">
                                        <div className="row">
                                            <h3>More about the product</h3>
                                        </div>
                                        <div className="row">
                                            <li><p><b>The Pilot-12 Lite is an Uninterruptible Power Supply (Backup Battery)</b> for all your handheld electronic devices and various 12V PAP machines while ensuring that your devices seamlessly continue to receive power even during a power outage.</p></li>
                                        </div>
                                        <div className="row">
                                            <div className="pic-col">
                                                <img src={require("../../img/media/medistrom/n/pilot12-lite/pilot-12-lite-connected-to-phillips-respironics-ac-adapter-and-the-phillips-respironics-dreamstation-pap-600px.jpg").default}/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <li><p><b>Can also be used as a portable, standalone power source.</b> Take it travelling and use it to power your PAP device when the mains power isn't available. Provides 1-2 nights of continuous run-time on an average pressure setting with the humidifier and heated tubing disabled. Will provide approximately 19,000mAh for your handheld electronic devices. </p></li>
                                        </div>
                                        <div className="row">
                                            <div className="pic-col">
                                                <img src={require("../../img/media/medistrom/n/pilot12-lite/pilot-12-lite-conected-to-phillips-respironics-dreamstation-pap-device-600px.jpg").default}/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <li><p><b>Features include</b> a built-in LED flashlight and USB Port for charging portable devices such as smart phones, tablets, MP3 players, cameras etc. </p></li>
                                        </div>
                                        <div className="row">
                                            <div className="pic-col">
                                                <img src={require("../../img/media/medistrom/n/pilot12-lite/pilot-12-24-lite-connected-to-smartphone-600px.jpg").default}/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <li><p><b>Compatible with</b> most handheld electronic devices that are equipped with USB charging. Compatible with most CPAP, APAP, and some BiPAP devices on the following platforms: Philips Respironics DreamStation, Philips Respironics System One S60 , DeVillbiss IntelliPAP I, IntelliPAP II and HDM Z1. <b className="red">Not compatible with ventilator PAP devices.</b> </p></li>
                                        </div>
                                    </div>
                                </ReactBootStrap.Tab>
                                <ReactBootStrap.Tab eventKey="included" title="What's Included">
                                    <div className="tab-container">
                                        <div className="row">
                                            <div className="pic-col">
                                                <img src={require("../../img/media/medistrom/n/pilot12-lite/pilot-12-24-lite-in-carry-sleeve-600px.jpg").default}/>
                                            </div>
                                            <div className="col">
                                                <h3>What's included</h3>
                                                <ul>
                                                    <li><p>Pilot-12 Lite Battery</p></li>
                                                    <li><p>Output Cable for Philips Respironics DreamStation/ System One S60 </p></li>
                                                    <li><p>Fabric Carrying Case</p></li>
                                                    <li><p>Product Manual (English, French, German, Spanish)</p></li>
                                                    <li><p>Quick Start Guide (English, French, German, Spanish)</p></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </ReactBootStrap.Tab>
                                <ReactBootStrap.Tab eventKey="bought" title="Frequently bought together">
                                    <div className="tab-container">
                                        <div className="row">
                                            <div className="pic-col">
                                                <img src={require("../../img/media/medistrom/n/pilot12-lite/pilot-12-24-lite-and-solar-panel-1-600px.jpg").default}/>
                                            </div>
                                            <div className="col">
                                                <h3>Frequently Bought Together</h3>
                                                
                                                    <ul>
                                                        <li><p>50W Solar Panel </p></li>
                                                        <li><p>Car Charger for Pilot-12/24 Lite </p></li>
                                                        <li><p>Mixed kit for Pilot-12 Lite<br/>Includes:</p>
                                                            <ul>
                                                                <li><p>DC Output Cable for IntelliPAP I/ HDM Z1 </p></li>
                                                                <li><p>Short Charging Adapter Cable for IntelliPAP I/ HDM Z1</p></li>
                                                                <li><p>DC Output Cable for IntelliPAP II</p></li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </ReactBootStrap.Tab>
                            </ReactBootStrap.Tabs>
                        </div>
                </div>
            }
            {this.props.objID=="pilot24lite" &&
                <div className="large-description">
                    <p>Introducing the New Pilot-24 Lite – revolutionary small Backup Power Supply/ Portable Travel Battery for ResMed and other 24V PAP Devices. <br/>The Pilot-24 Lite is compatible with: </p>
                    <p><ul>
                        <li>ResMed AirSense 11*</li>
                        <li>ResMed AirSense 10</li>
                        <li>ResMed AirMini</li>
                        <li>ResMed S9*</li>
                        <li>Sefam S.Box*</li>
                        <li>Löwenstein Medical prismaSMART* (Included in Europe)</li>
                        <li>Philips Respironics DreamStation Go*</li>
                        <li>3B Medical Luna**</li>
                        <li>Apex Medical iCH**</li>
                        <li>Philips Respironics DreamStation Go Travel PAP** (**Cable Kits sold separately)</li>
                        <p><span style={{fontSize:"13px"}}>(*Cable Kits sold separately)</span></p>
                        </ul></p> 
                    <p>While using the Pilot-24 Lite as a permanent power backup device, you can rest assured that you won't be woken in the middle of the night to find that your CPAP machine is not running. If the power is interrupted at night, your CPAP machine will drive power from your Pilot-24 Lite without any interruption.</p>
                    <p>Using Pilot-24 Lite as a Portable Travel PAP Battery will help you receive PAP therapy while traveling. This includes remote locations where mains power is not available in situations such as camping, road trips and during air travel.</p>
                    <p>The Pilot-24 Lite can also be used as a charging station for various electronic devices. With the built-in USB port it is capable of powering a variety of handheld electronic devices such as mobile phones, tablets and cameras.</p>

                    <div className="tabs-container">
                        <ReactBootStrap.Tabs defaultActiveKey="features" id="uncontrolled-tab-example">
                            <ReactBootStrap.Tab eventKey="features" title="Pilot-24 Lite Features">
                                <div className="tab-container">
                                    <div className="row">
                                        <div className="pic-col">
                                            <img src={require("../../img/media/medistrom/n/pilot12-lite/pilot-12-lite-control-panel-508px.jpg").default}/>
                                        </div>
                                        <div className="col">
                                            <h3>Medistrom™ Pilot-24 Lite Features</h3>
                                            <p><ul>
                                                <li>Can be used as a Permanent Backup Power Supply or a Portable Travel Battery for common 24V PAP Devices
                                                    <ul>
                                                        <li>ResMed AirSense 11</li>
                                                        <li>ResMed AirSense 10</li>
                                                        <li>ResMed AirMini*  (*In order to run Pilot-24 Lite in backup configuration with AirMini, please use Medistrom AC Power Adapter (SKU #P24ACCACA), or ResMed original AC power adapter for AirSense 10)</li>
                                                        <li>ResMed S9**</li>
                                                        <li>3B Medical Luna**</li>
                                                        <li>Apex Medical iCH**</li>
                                                        <li>Philips Respironics DreamStation GO Travel CPAP**(** Cable Kits sold separately)</li>
                                                    </ul>
                                                </li>
                                                <li>Can be used as a Portable Charging Station for most handheld electronic devices such as phones, tablets, and camera</li>
                                                <li>Features a built-in LED Flashlight</li>
                                                <li>FAA Compliant</li>
                                            </ul></p>
                                        </div>
                                    </div>
                                </div>
                            </ReactBootStrap.Tab>
                            <ReactBootStrap.Tab eventKey="specs" title="Specifications">
                                <div className="tab-container">
                                    <div className="row">
                                        <h3>Specifications</h3>
                                    </div>
                                    <div className="row">
                                        <ReactBootStrap.Table striped bordered className="spec-table">
                                            <tbody>
                                                <tr>
                                                    <td width="22%">Capacity:</td>
                                                    <td>95Wh</td>
                                                </tr>
                                                <tr>
                                                    <td width="22%">Cell Type:</td>
                                                    <td>Industrial grade genuine LG rechargeable lithium ion cells</td>
                                                </tr>
                                                <tr>
                                                    <td width="22%">Rated Input: </td>
                                                    <td>24V, 60W (NORMAL), 90W (MAX)</td>
                                                </tr>
                                                <tr>
                                                    <td width="22%">Rated Output:</td>
                                                    <td>DC 24V, 3.75A </td>
                                                </tr>
                                                <tr>
                                                    <td width="22%">USB Out:</td>
                                                    <td>5V, 2.5A</td>
                                                </tr>
                                                <tr>
                                                    <td width="22%">Life Span:</td>
                                                    <td>Over 500 full charge/discharge cycles</td>
                                                </tr>
                                                <tr>
                                                    <td width="22%">Case:</td>
                                                    <td>Aluminium</td>
                                                </tr>
                                                <tr>
                                                    <td width="22%">Dimensions:</td>
                                                    <td>17cm x 9cm x 2cm (6.7" x 3.5" x 0.8")</td>
                                                </tr>
                                                <tr>
                                                    <td width="22%">Weight:</td>
                                                    <td>560g (1.3lbs)</td>
                                                </tr>
                                                <tr>
                                                    <td width="22%">Power Gauge:</td>
                                                    <td>4 stage Charge Level Indicator Lights</td>
                                                </tr>
                                                <tr>
                                                    <td width="22%">Recharge time:</td>
                                                    <td>2-3 hours via Respironics DreamStation AC Adapter (charging via other compatible adapters may take longer)</td>
                                                </tr>
                                            </tbody>  
                                        </ReactBootStrap.Table>
                                    </div>
                                </div>
                            </ReactBootStrap.Tab>
                            <ReactBootStrap.Tab eventKey="about" title="More about the product">
                                <div className="tab-container">
                                    <div className="row">
                                        <h3>More about the product</h3>
                                    </div>
                                    <div className="row">
                                        <li><p><b>The Pilot-24 Lite is an Uninterruptible Power Supply (Backup Battery)</b> for all your handheld electronic devices and various 24V PAP machines and ensures that your devices seamlessly continue to receive power even during a power outage.</p></li>
                                    </div>
                                    <div className="row">
                                        <div className="pic-col">
                                            <img src={require("../../img/media/medistrom/n/pilot24-lite/pilot-24-lite-connected-to-ac-adapter-and-resmed-airsense-10-pap-device-600px.jpg").default}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <li><p><b>Can also be used as a portable, standalone power source.</b> Take it travelling and use it to power your PAP device when the mains power isn't available. Provides 1-2 nights of continuous run-time on an average pressure setting with the humidifier and heated tubing disabled. Will provide approximately 19,000mAh for your handheld electronic devices. </p></li>
                                    </div>
                                    <div className="row">
                                        <div className="pic-col">
                                            <img src={require("../../img/media/medistrom/n/pilot24-lite/pilot-24-lite-connected-to-resmed-mini-600px.jpg").default}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <li><p><b>Features include</b> a built-in LED flashlight and USB Port for charging portable devices such as smart phones, tablets, MP3 players, cameras etc. </p></li>
                                    </div>
                                    <div className="row">
                                        <div className="pic-col">
                                            <img src={require("../../img/media/medistrom/n/pilot12-lite/pilot-12-24-lite-connected-to-smartphone-600px.jpg").default}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <li><p><b>Compatible with</b> most handheld electronic devices that are equipped with USB charging. Compatible with most CPAP, APAP, and some BiPAP devices on the following platforms: ResMed Airsense 10, ResMed AirMini, ResMed S9, 3B Medical Luna, Apex Medical iCH and Respironics DreamStation Go Travel PAP. <b className="red">Not compatible with ventilator PAP devices.</b> </p></li>
                                    </div>
                                </div>
                            </ReactBootStrap.Tab>
                            <ReactBootStrap.Tab eventKey="included" title="What's Included">
                                <div className="tab-container">
                                    <div className="row">
                                        <div className="pic-col">
                                            <img src={require("../../img/media/medistrom/n/pilot12-lite/pilot-12-24-lite-in-carry-sleeve-600px.jpg").default}/>
                                        </div>
                                        <div className="col">
                                            <h3>What's included</h3>
                                            <p><ul>
                                                <li>Pilot-24 Lite Battery</li>
                                                <li>Output Cable for ResMed AirSense 10 </li>
                                                <li>Output Cable for ResMed AirMini</li>
                                                <li>Short Charging adapter for ResMed AirMini </li>
                                                <li>Fabric Carrying Case</li>
                                                <li>Product Manual (English, French, German, Spanish)</li>
                                                <li>Quick Start Guide (English, French, German, Spanish)</li>
                                            </ul></p>
                                        </div>
                                    </div>
                                </div>
                            </ReactBootStrap.Tab>
                            <ReactBootStrap.Tab eventKey="bought" title="Frequently bought together">
                                <div className="tab-container">
                                    <div className="row">
                                        <div className="pic-col">
                                            <img src={require("../../img/media/medistrom/n/pilot12-lite/pilot-12-24-lite-and-solar-panel-1-600px.jpg").default}/>
                                        </div>
                                        <div className="col">
                                            <h3>Frequently Bought Together</h3>
                                            <p>
                                                <ul>
                                                    <li>50W Solar Panel </li>
                                                    <li>Car Charger for Pilot-12/24 Lite </li>
                                                    <li>Philips Respironics DreamStation Go/3B Medical Luna Kit for Pilot-24 Lite<br/>Includes:
                                                        <ul>
                                                            <li>Philips Respironics DreamStation Go DC Output Cable (ATTENTION! Philips Respironics DreamStation Go is compatible with Pilot-24 series batteries ONLY)</li>
                                                            <li>Pilot-24 Lite AC Power Adapter</li>
                                                            <li>3B Medical Luna DC Output Cable </li>
                                                        </ul>
                                                    </li>
                                                    <li>ResMed S9 Kit for Pilot-24 Lite<br/>Includes:
                                                        <ul>
                                                            <li>DC Output Cable for ResMed S9</li>
                                                            <li>Short Charging Adapter Cable for ResMed S9</li>
                                                        </ul>
                                                    </li>
                                                    <li>Apex Medical iCH kit for Pilot-24 Lite<br/>Includes:
                                                        <ul>
                                                            <li>Output cable for Apex Medical iCH</li>
                                                            <li>Short Charging Adapter Cable for Apex Medical iCH</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </ReactBootStrap.Tab>
                        </ReactBootStrap.Tabs>
                    </div>
            </div>
            }
            {this.props.objID=="carcharger" &&
                <div className="large-description">
                    <p>Easy to use: plug one end of the Medistrom™ Car Charger into the car cigarette lighter socket, and the other end into the "CHARGE" port of your Pilot-12 Lite or Pilot-24 Lite. The battery will charge in 3-5 hours*  (*charge times may vary based on vehicle make, model and vehicle battery condition). </p>
                    <p><b className="red">CAUTION!</b> Make sure the battery is NOT connected to a PAP device while charging via car charger! </p>
                </div>
            }
            {this.props.objID=="solarpanel" &&
                <div className="large-description">
                    <p>Easy to use. Charge times may vary depending on the weather.</p>
                    <p><b className="red">CAUTION!</b> Make sure the battery is NOT connected to a PAP device while charging via solar panel! </p>
                </div>
            }
            {this.props.objID=="s9kit" &&
                <div>
                </div>
            }
            {this.props.objID=="dreamstation" &&
                <div>
                </div>
            }
            {this.props.objID=="acadapter" &&
                <div>
                </div>
            }
            {this.props.objID=="mixedkit" &&
                <div>
                </div>
            }
            {this.props.objID=="handsanitizer" &&
                <div className="large-description">
                    <div className="row">
                        <h3>Features</h3>
                    </div>
                    <div className="row">
                        <ul>
                            <li>
                                <p><b>Antiseptic Protection</b></p>
                                <p>Kills bacteria & viruses on contact </p>
                            </li>
                            <li>
                                <p><b>Cleanliness</b></p>
                                <p>Use whenever soap and water are not available and to supplement routine hand washing to kill any residual microbes. </p>
                            </li>
                            <li>
                                <p><b>Softer Hands</b></p>
                                <p>Enriched with aloe vera to avoid drying out hands</p>
                            </li>
                        </ul>
                    </div>
                    <div className="row">
                        <h3>Ingredients</h3>
                        <p>Ethanol 62%, Water, Carbomer, Triethanolamine, Glycerin, Propylene Glycol, Tocopheryl Acetate, Parfum (Fragrance), Aloe Barbaddensis Leaf Extract. </p>
                    </div>
                    <div className="row">
                        <Link to="/sanitizer_info">
                            <ReactBootStrap.Button className="pdfbutton" variant="success">More Info</ReactBootStrap.Button>
                        </Link>
                    </div>
                </div>
            }
            {this.props.objID=="hosestandard" &&
                <div>
                </div>
            }
            {this.props.objID=="hoseslim" &&
                <div>
                </div>
            }
            {this.props.objID=="aloecan" &&
                <div>
                </div>
            }
            {this.props.objID=="citruscan" &&
                <div>
                </div>
            }
            {this.props.objID=="aloetravel" &&
                <div>
                </div>
            }
            {this.props.objID=="citrustravel" &&
                <div>
                </div>
            }
            {this.props.objID=="childwipes" &&
                <div>
                </div>
            }
            {this.props.objID=="unscentedsoap" &&
                <div>
                </div>
            }
            {this.props.objID=="scentedsoap" &&
                <div>
                </div>
            }
            {this.props.objID=="twoinonebrush" &&
                <div>
                </div>
            }
            {this.props.objID=="hosebrush" &&
                <div>
                </div>
            }
            {this.props.objID=="levata" &&
                <div className="large-description">
                    <div className="row">
                        <img src={require("../../img/best-in-rest/Levata-weighted-blanket/levata-weighted-blanket.jpg").default} width="70%"/>
                    </div>
                    <div className="tabs-container">
                            <ReactBootStrap.Tabs defaultActiveKey="features" id="uncontrolled-tab-example">
                                <ReactBootStrap.Tab eventKey="features" title="Features and Benefits">
                                    <div className="tab-container">
                                        <div className="row">
                                            <h3>FEATURES</h3>
                                        </div>
                                        <div className="row">
                                            <img width="7%" src={require("../../img/best-in-rest/Levata-weighted-blanket/icon-non-toxic.png").default}/>
                                            <p><br/>&nbsp;&nbsp;&nbsp;Made with non-toxic, stable, lead free, weighted micro glass beads </p>
                                        </div>
                                        <div className="row">
                                            <img width="7%" src={require("../../img/best-in-rest/Levata-weighted-blanket/icon-cotton.png").default}/>
                                            <p><br/>&nbsp;&nbsp;&nbsp;100% cotton and non-woven breathable fabric for maximum comfort</p>                 
                                        </div>
                                        <div className="row">
                                            <img width="7%" src={require("../../img/best-in-rest/Levata-weighted-blanket/icon-deep-pressure.png").default}/>
                                            <p><br/>&nbsp;&nbsp;&nbsp;Utilizes deep pressure stimulation to help improve sleep quality, restlessness and anxiety  </p> 
                                        </div>
                                        <div className="row">
                                            <img width="7%" src={require("../../img/best-in-rest/Levata-weighted-blanket/icon-weighted.png").default}/>
                                            <p><br/>&nbsp;&nbsp;&nbsp;Weighted to evenly provide pressure throughout the body</p> 
                                        </div>
                                        <div className="row">
                                            <img width="7%" src={require("../../img/best-in-rest/Levata-weighted-blanket/icon-machine-washable.png").default}/>
                                            <p><br/>&nbsp;&nbsp;&nbsp;Easy care, machine washable, plush removable cover</p> 
                                        </div>
                                        <div className="row">
                                            <img width="7%" src={require("../../img/best-in-rest/Levata-weighted-blanket/icon-quality.png").default}/>
                                            <p><br/>&nbsp;&nbsp;&nbsp;Engineered with 7 layers to deliver the highest quality</p> 
                                        </div>
                                        <div className="row">
                                                <h3>BENEFITS</h3>
                                            </div>
                                            <div className="row">
                                                <p>Researchers have studied weighted blankets’ effectveness for
various conditons and discovered the following benefits:</p>
                                            </div>
                                            <div className="row">
                                                <h5>Deep Pressure Stimulaton</h5>
                                            </div>
                                            <div className="row">
                                                <p>The pressure from the Levätä weighted blanket, promotes a
    therapeutic technique called deep pressure stmulaton. This
    technique is used to relax the nervous system. Doing so may help: </p>
                                            </div>
                                            <div className="row">
                                                <ul>
                                                    <li>
                                                        <p>Relieve pain</p>
                                                    </li>
                                                    <li>
                                                        <p>Less anxiety</p>
                                                    </li>
                                                    <li>
                                                        <p>Improve moods</p>
                                                    </li>
                                                    <li>
                                                        <p>Promote calmness</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="row">
                                                <h5>Insomnia and Sleep Disorders</h5>
                                            </div>
                                            <div className="row">
                                                <p>The pressure can help boost serotonin and melatonin levels.
    When serotonin increases, it can be easier to relax into a more
    restful sleep and induce REM cycles.</p>
                                            </div>
                                            <div className="row">
                                                <h5>Other benefits of Levätä</h5>
                                            </div>
                                            <div className="row">
                                                <ul>
                                                    <li><p>Helps with Sensory Processing Disorder</p></li>
                                                    <li><p>Fights Back Against Stress</p></li>
                                                    <li><p>Manages Autism Symptoms</p></li>
                                                    <li><p>Improves Focus for ADHD</p></li>
                                                    <li><p>Helps the Elderly</p></li>
                                                    <li><p>Eases Symptoms of Restless Leg Syndrome</p></li>
                                                    <li><p>Addresses Symptoms of PTSD</p></li>
                                                    <li><p>Soothes Symptoms of Panic Disorders</p></li>
                                                    <li><p>Decreases Anxiety in Pets</p></li>
                                                    <li><p>Recreates a Hug</p></li>
                                                </ul>
                                            </div>
                                    </div>
                                </ReactBootStrap.Tab>
                                <ReactBootStrap.Tab eventKey="specs" title="Specifications">
                                    <div className="tab-container">
                                        <div className="row">
                                            <h3>SIZE</h3>
                                        </div>
                                        <div className="row">
                                            <p>60” x 80” (Queen) </p>
                                        </div>
                                        <div className="row">
                                            <h3>WEIGHT</h3>
                                        </div>
                                        <div className="row">
                                            <p>20 lbs</p>
                                        </div>
                                    </div>
                                </ReactBootStrap.Tab>
                            </ReactBootStrap.Tabs>
                        </div>
                </div>
            }
            {this.props.objID=="chinstrap" &&
                <div className="large-description">
                    <div className="row">
                        <p><b>Improve PAP Therapy</b></p>
                    </div>
                    <div className="row">
                        <p>This device easily and gently cradles the jaw to prevent the mouth from opening during PAP treatment to minimize air loss. </p>
                    </div>
                    <div className="row">
                        <p><b>Reduce Snoring</b></p>
                    </div>
                    <div className="row">
                        <p>It also reduces snoring by keeping the mouth closed, it will force the user to breathe through the nose to sleep comfortably without snoring.</p>
                    </div>
                    <div className="row">
                        <h3>Size</h3>
                    </div>
                    <div className="row">
                        <p>One size</p>
                    </div>
                    <div className="row">
                        <h3>Features</h3>
                    </div>
                    <div className="row">
                        <ul>
                            <li>Includes adjustable strap for best fit </li>
                            <li>Made with high quality, soft, breathable cloth for maximum comfort </li>
                            <li>Easy to use and maintain</li>
                        </ul>
                    </div>
                </div>
            }
            {this.props.objID=="gelpillow" &&
                <div className="large-description">
                    <div className="row">
                        <p>
                        <img className="smallimg" width="7%" src={require("../../img/media/best-in-rest/cpap-memory-foam-pillow-with-cooling-gel/icon-gel.png").default}/>
                        <h3 className="bir">Cooling Gel</h3>
                        Cooling gel makes you feel cool in summer and warm in winter. <br/>
                        This layer will keep the temperature down and help with comfort no matter what mask you are wearing or what position you are sleeping in. 
                        </p>
                    </div>
                    <div className="row">
                        <p>
                        <img className="smallimg" width="7%" src={require("../../img/media/best-in-rest/cpap-memory-foam-pillow-with-cooling-gel/icon-foam.png").default}/>
                        <h3 className="bir">MEMORY FOAM</h3>
                        The luxury memory foam keeps the body in proper alignment while alleviating uncomfortable pressure points that one would get with a regular pillow.  
                        </p>
                    </div>
                    <div className="row">
                        <p>
                        <img className="smallimg" width="20%" src={require("../../img/media/best-in-rest/cpap-memory-foam-pillow-with-cooling-gel/icon-vent.png").default}/>
                        <h3 className="bir">VENTILATED MEMORY FOAM</h3>
                        Promotes air circulation & reduces moisture build up  
                        </p>
                    </div>
                    <div className="row">
                        <p>
                        <img className="smallimg" width="8.5%" src={require("../../img/media/best-in-rest/cpap-memory-foam-pillow-with-cooling-gel/icon-bamboo.png").default}/>
                        <h3 className="bir">Natural bamboo pillowcase</h3>
                        Includes a breathable pillowcase made from natural bamboo and developed specifically to fit the unique shape of the CPAP pillow.   
                        </p>
                    </div>
                    <div className="row">
                        <h3>Features</h3>
                    </div>
                    <div className="row">
                        <p>Size 33 x 61 cm / 13 x 24 inches</p>
                    </div>
                    <div className="row">
                        <p>
                            <ul>
                                <li>Specifically designed for PAP machine users</li>
                                <li>Natural bamboo pillowcase </li>
                                <li>Proper body alignment</li>
                                <li>Luxury memory foam </li>
                                <li>1 Year Warranty</li>
                            </ul>
                        </p>
                    </div>
                    <hr/>
                    <div className="row"><h3>Our Commitment to You</h3></div>
                    <div className="row"><p>At Choice One Medical, we believe in delivering nothing but the best. When creating a new product we do a tremendous amount of research to ensure it makes a positive difference in the lives of our customers. By combining, design, innovation and science we are able to create a pillow like no other to improve sleep. </p></div>
                    <div className="row"><h3>Our Difference</h3></div>
                    <div className="row"><p>Specifically designed to give exceptional support and comfort when wearing CPAP masks. The unique cut-outs reduces mask leak which allows the CPAP user to gain the full recommended therapy. Most memory foam pillows incorporates a regular foam in the center which does not provide the necessary support overtime. Understanding this, we made all 3 layers with 100% memory foam to ensure it holds the user's mould and giving superior support to last throughout the night and years to come. Another challenge we found was heat build-up in memory foam and with CPAP patients using humidifier. This was resolved by incorporating perforated layers and mesh to the sides of the pillowcase to promote air circulation from the core of the pillow to the pillowcase. </p></div>
                    <div className="row"><h3>Final Result</h3></div>
                    <div className="row"><p>The first luxurious premium quality reversible pillow of Best in Rest™ new patented revolutionary technology of herbal infusion memory foam that help CPAP users achieve a deep relaxing sleep like no other CPAP pillow was able to do before. </p></div>
                </div>
            }
            {this.props.objID=="pillow" &&
                <div className="large-description">
                    <div className="row">
                        <h3>Features</h3>
                    </div>
                    <div className="row">
                        <p>Size 33 x 61 cm / 13 x 24 inches</p>
                    </div>
                    <div className="row">
                        <p>
                            <ul>
                                <li>Specifically designed for PAP machine users</li>
                                <li>Moulds to the best shape for your body</li>
                                <li>Ventilated memory foam</li>
                                <li>Infused with herbal green tea</li>
                                <li>Mint & Bamboo Infused Cooling Gel Memory Foam</li>
                                <li>Adjustable Pillow Height</li>
                                <li>Ideal for sleeping in multiple positions</li>
                                <li>100% Cotton pillowcase</li>
                                <li>Easy care luxury 100% cotton pillow case</li>
                                <li>1 Year Warranty</li>
                            </ul>
                        </p>
                    </div>
                    <div className="row">
                        <img src={require("../../img/media/best-in-rest/memory-foam-pillow-info.jpg").default}/>
                    </div>
                    <hr/>
                    <div className="row"><h3>Our Commitment to You</h3></div>
                    <div className="row"><p>At Choice One Medical, we believe in delivering nothing but the best. When creating a new product we do a tremendous amount of research to ensure it makes a positive difference in the lives of our customers. By combining, design, innovation and science we are able to create a pillow like no other to improve sleep. </p></div>
                    <div className="row"><h3>Our Difference</h3></div>
                    <div className="row"><p>Specifically designed to give exceptional support and comfort when wearing CPAP masks. The unique cut-outs reduces mask leak which allows the CPAP user to gain the full recommended therapy. Most memory foam pillows incorporates a regular foam in the center which does not provide the necessary support overtime. Understanding this, we made all 3 layers with 100% memory foam to ensure it holds the user's mould and giving superior support to last throughout the night and years to come. Another challenge we found was heat build-up in memory foam and with CPAP patients using humidifier. This was resolved by incorporating perforated layers and mesh to the sides of the pillowcase to promote air circulation from the core of the pillow to the pillowcase. </p></div>
                    <div className="row"><h3>Final Result</h3></div>
                    <div className="row"><p>The first luxurious premium quality reversible pillow of Best in Rest™ new patented revolutionary technology of herbal infusion memory foam that help CPAP users achieve a deep relaxing sleep like no other CPAP pillow was able to do before. </p></div>
                </div>
            }
            {this.props.objID=="childpillow" &&
                <div className="large-description">
                    <div className="row">
                        <h3>Features</h3>
                    </div>
                    <div className="row">
                        <p>Size 18.9 x 9.8 inches</p>
                    </div>
                    <div className="row">
                        <p>
                            <ul>
                                <li>Ideal for sleeping in multiple positions</li>
                                <li>Provides proper alignment of spine & neck for the best sleeping position by filling the natural curvature of the neck</li>
                                <li>Elevates, aligns and opens the airway for easier breathing</li>
                                <li>Keeping head and spine in alignment brings the tongue and lower jaw forward allowing air to flow freely through the airway thus reducing snoring and improving quality of sleep</li>
                                <li>Memory foam moulds to the best shape for a child's body helping prevent flat head</li>
                                <li>Adjustable height makes pillow ideal for children 2 years and older</li>
                                <li>Luxury 100% bamboo pillowcase</li>
                                <li>Hypoallergenic, anti-mite</li>
                                <li>Breathable for air circulation, draws away moisture, reduces sweating</li>
                            </ul>
                        </p>
                    </div>
                    <div className="row">
                        <img src="http://bestinrest.com/img/products/memory-foam-pillow-for-children-breakdown.jpg"/>
                    </div>
                    <hr/>
                    <div className="row"><h3>Our Commitment to You</h3></div>
                    <div className="row"><p>At Choice One Medical, we believe in delivering nothing but the best. When creating a new product we do a tremendous amount of research to ensure it makes a positive difference in the lives of our customers. By combining, design, innovation and science we are able to create a pillow like no other to improve sleep. </p></div>
                    <div className="row"><h3>Our Difference</h3></div>
                    <div className="row"><p>Specifically designed to give exceptional support and comfort to children sleeping in multiple positions. Our adjustable memory foam pillow unlike others is built to ensure consistent support for children as young as 2 years of age and provide comfort for many years to come. Regular memory foam pillows suffer from poor air circulation and heat build-up resulting in excessive sweating and poor sleep. Our memory foam pillows incorporate perforated layers to promote air circulation from the core of the pillow throughout. </p></div>
                    <div className="row"><h3>Final Result</h3></div>
                    <div className="row"><p>A luxurious premium quality adjustable memory foam pillow with 100% bamboo pillowcase that helps children achieve a deep relaxing sleep like no other pillow before it.  </p></div>
                </div>
            }
            {this.props.objID=="eyemask" &&
                <div className="large-description">
                    <div className="row">
                        <h3>Features</h3>
                    </div>
                    <div className="row">
                        <p>
                            <ul>
                                <li>High quality luxury eye mask helps you achieve the most comfortable sleep</li>
                                <li>Memory foam moulds to the best shape for your face to block unwanted light & help you achieve the deepest sleep</li>
                                <li>Hot & cold gel inserts can be warmed up or cooled down to help you relax & ease headaches & migraines </li>
                                <li>Soft memory foam earplugs help you block unwanted sounds</li>
                                <li>Electrized strap provides a snug adjustable fit</li>
                            </ul>
                        </p>
                    </div>
                </div>
            }
          { /* this.props.objID=="belt" &&
                <div className="large-description">
                    <div className="row">
                        <img src={require("../../img/media/best-in-rest/belt/No-Snoring-Illustration-01.jpg").default}/>
                    </div>
                    <div className="row">
                        <h3>What's Included</h3>
                    </div>
                    <div className="row">
                        <p>
                            <ul>
                                <li>One smart position control therapy device </li>
                                <li>One adjustable belt</li>
                                <li>Two adjustable fixing clips</li>
                                <li>Two type AAA 1.5 V batteries </li>
                                <li>Instructions for use.</li>
                            </ul>
                        </p>
                    </div>
                    <p><b>Sizes:</b> (S/M/L) or (XL/XXL) </p>
                </div>
        */}
            {this.props.objID=="seatcushion" &&
                <div className="large-description">
                    <div className="row">
                        <h3>Features</h3>
                    </div>
                    <div className="row">
                    
                    </div>
                    <div className="row">
                        <p>
                            <ul>
                                <li>Made with high quality <b>memory foam</b> with <b>cooling gel</b> layer</li>
                                <li>Innovatively designed for <b>hemorrhoid relief</b></li>
                                <li>Cut out below rectal area helps <b>ease your body weight</b> off the affected area</li>
                                <li>Provides comfortable sitting by <b>dispersing pressure away</b> from rectal area</li>
                                <li><b>Improves posture</b> by providing support</li>
                                <li>Ideal way to <b>prevent hemorrhoids</b> in those who sit for long hours</li>
                                <li><b>Great choice for new mothers</b> recovering from perennial tears or episiotomy</li>
                                <li>Comfortable velvet case is <b>easily washable</b></li>
                                <li>Memory foam <b>moulds to the best shape</b> for your body providing constant posture support for comfortable sitting</li>
                                <li>Cooling gel layer <b>alleviates body heat</b> and prevents uncomfortable sweating</li>
                            </ul>
                        </p>
                    </div>
                    <div className="row">
                        <div className="col">
                            <img className="embeddedimg" src={require("../../img/media/best-in-rest/n/seat-cushion/seat-cushion-design-1.jpg").default}/>
                        </div>
                        <div className="col">
                            <img className="embeddedimg" src={require("../../img/media/best-in-rest/n/seat-cushion/seat-cushion-design-2.jpg").default}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">With Best in Rest Memory Foam Orthopedic Seat Cushion, your body weight is distributed off the affected area and is absorbed by the cushion. This provides comfortable sitting, even for extended periods of time, by dispersing pressure away from rectal area.</div>
                        <div className="col">While sitting on regular chair, the full load of your body weight is borne at the bottom, which puts heavy pressure around your rectal area. Sitting for long hours on regular surfaces makes current hemorrhoid increase, or puts you at risk of developing hemorrhoid in a future.</div>
                    </div>
                </div>
            }
            {this.props.objID=="hoselift" &&
            <div className="large-description">
                <h3>Features</h3>
                    <p>
                        <ul>
                            <li>Adjustable Arm: Incorporates a secure adjustable arm that smoothly moves and extends with the user’s movement to avoid pulling or tugging of the hose. </li>
                            <li>Flexible: The extendable rod also combines a level of flexibility to adapt to the shape of the space needed for the user’s convenience and comfort.</li>
                            <li>Extendable: The aluminum telescopic rod can be extended and retracted allowing the user to adjust the height of the hose lift with ease. </li>
                            <li>Grip on clamp: Sleek and sturdy base with thick non-slip silicone lining to ensure a secure firm grip. Can be adjusted to clamp surfaces from .25” to 3.25”.</li>
                            <li>Assembly: This Best in Rest Hose lift comes fully assembled, simply adjust to your ideal size and position. Quick disassembly allows for easy and convenient storage.</li>
                        </ul>
                    </p>
                </div>
            }
            {this.props.objID=="adjustablememorypillow" &&
                <div className="large-description">
                    <h3>Features</h3>
                    <p>
                        <ul>
                            <li>100% Memory Foam moulds to the best shape for your body</li>
                            <li>Ventilated memory foam promotes air circulation and reduces moisture buildup</li>
                            <li>Infused with bamboo charcoal and mine extract to reduce perspiration and help stay cool</li>
                            <li>Green Tea herbal infused layer can help relieve headaches &amp; migraines. Neutralizes odors for lasting freshness and offers a pleasant scent</li>
                            <li>The middle memory foam layer can be removed to allow users to customize the height</li>
                            <li>Comes with a 100% cotton pillowcase that can be removed and is machine washable</li>
                        </ul>
                    </p>
                    </div>
            }
            {this.props.objID=="dustcover" &&
                <div className="large-description">
                        <h3>Features</h3>
                    <p>
                        <ul>
                            <li>Captures water leaks</li>
                            <li>Protects furniture against scratches</li>
                            <li>Anti-slip silicone mat helps to keep your CPAP machine in place</li>
                            <li>Absorbs vibration and reduces noise</li>
                            <li>Protects CPAP device, mask and hose from dust, dirt, airborne particles, water, animal hair, and much more</li>
                            <li>Cover is machine washable and mat is dishwasher friendly</li>
                            <li>Mat is BPA free and environmentally friendly</li>
                            <li>Flexible, soft and can be folded or rolled up for easy storage</li>
                        </ul>
                    </p>
                </div>
            }
            {this.props.objID=="cpapfilters" &&
                <div className="large-description">
                    <h3>Features</h3>
                    <p>
                        <ul>
                            <li>Attractively packaged for store front sales</li>
                            <li>Perfect Fit and Filtration</li>
                            <li>Helps achieve optimal PAP Therapy</li>
                        </ul>
                    </p>
                    <p>Available in:</p>
                    <p>ResMed AirSense 11 & S11 Standard (3 Pack)</p>
                    <p>ResMed AirSense 11 & S11 Standard (15 Pack)</p>
                    <p>ResMed AirSense 10 & S9 Standard (3 Pack)</p>
                    <p>ResMed AirSense 10 & S9 Standard (15 Pack)</p>
                    <p>ResMed AirSense 10 & S9 Ultra fine (3 Pack)</p>
                    <p>ResMed AirMini (3 Pack)</p>
                    <p>ResMed AirMini (15 Pack)</p>
                    <p>Respironics DreamStation Reusable Pollen (2 Pack)</p>
                    <p>Respironics DreamStation Disposable Ultra Fine (5 Pack)</p>
                </div>
            }
            {this.props.objID=="disinfectingwipes" &&
                <div className="large-description">
                    <h3>Features</h3>
                    <p>
                    <ul>
                        <li>Kills 99.99% of bacteria &amp; viruses</li>
                        <li>Fast and effective way to clean, deodorize and disinfect surfaces</li>
                        <li>Hypoallergenic &amp; alcohol free. Infused with Aloe Vera to be gentle on sensitive skin and leave a fresh natural fragrance</li>
                        <li>Specially formulated to clean and aid in the reduction of cross-contamination</li>
                        <li>Made from 100% Cotton - Highly durable and absorbent for the toughest messes</li>
                    </ul>
                    </p>
                    <h3>Ingredients</h3>

                    <p>water (aqua), glycerin, ethylhexylglycerin, aloe barbadensis leaf extract, phenoxyethanol, propylene glycol, fragrance</p>
                </div>
            }
            {this.props.objID=="sanitizingwipes" &&
                <div className="large-description">
                    <h3>Features</h3>
                    <p>
                    <ul>
                        <li>Kills 99.99% of bacteria &amp; viruses</li>
                        <li>Fast and effective way to clean, deodorize and disinfect surfaces</li>
                        <li>Hypoallergenic &amp; alcohol free. Infused with Aloe Vera to be gentle on sensitive skin and leave a fresh natural fragrance</li>
                        <li>Specially formulated to clean and aid in the reduction of cross-contamination</li>
                        <li>Made from 100% Cotton - Highly durable and absorbent for the toughest messes</li>
                    </ul>
                    </p>
                    <h3>Ingredients</h3>

                    <p>aloe barbadense leaf juice, ethylhexylglycerin, fragrance, glycerin, propylene glycol, purified water USP</p>
                </div>
            }
        </div>
    );
  }
}