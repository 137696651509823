import React from 'react';
import PageHeader from "./PageHeader";
import ProductRow from './ProductRow';
import data from '../data.json';
import './BrandPage.css';
import logo from "../img/logos/logo-2.png";
import ProductPage from "./ProductPage";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

export default class BestInRest extends React.Component {
  constructor(){
    super();
  }
  

  render(){
    
    return (
      <div className="product-page">
        <PageHeader title={"Our Products"}/>
        <div className="product-page-container">
          <div className="brand">
            <img src={logo}/>
          </div>
          <div className="brand-description">
            To become our dealer, please contact us at (888) 400-8987.
          </div>
          <ProductRow obj={data.adjustablememorypillow_obj}/>
          <ProductRow obj={data.hoselift_obj}/>
          <ProductRow obj={data.levata_obj}/>
          <ProductRow obj={data.chinstrap_obj}/>
          <ProductRow obj={data.gelpillow_obj}/>
          <ProductRow obj={data.pillow_obj}/>
          <ProductRow obj={data.childpillow_obj}/>
          <ProductRow obj={data.eyemask_obj}/>
        {/* <ProductRow obj={data.belt_obj}/> */}
          <ProductRow obj={data.seatcushion_obj}/>
        </div>

      </div>
    );
  }
}