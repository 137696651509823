import React from 'react';
import PageHeader from "./PageHeader";
import './BrandPage.css';
import logo from "../img/logos/logo-2.png";
import AlbumRow from "./products/AlbumRow";

export default class MediaBIR extends React.Component {
  constructor(){
    super();
  }
  render(){
    return (
      <div className="product-page">
        <PageHeader title={"Our Products"}/>
        <div className="product-page-container">
          <div className="brand">
            <img src={logo}/>
          </div>
          <AlbumRow title = 'Best in Rest Adjustable 2 in 1 Memory Foam Pillow'
          files={[
            require('../img/media/best-in-rest/2-in-1_adjustable_memory_foam/IMG_0659.jpg').default,
            require('../img/media/best-in-rest/2-in-1_adjustable_memory_foam/layers exposed.jpg').default,
            require('../img/media/best-in-rest/2-in-1_adjustable_memory_foam/pillowcase.jpg').default,
            require('../img/media/best-in-rest/2-in-1_adjustable_memory_foam/sleeping.jpg').default,
          ]}/>
          <AlbumRow title = 'Hose Lift'
          files={[
            require('../img/media/best-in-rest/Hose_Lift/Folded Lift.jpg').default,
            require('../img/media/best-in-rest/Hose_Lift/Clamped Base.jpg').default,
            require('../img/media/best-in-rest/Hose_Lift/Extendable Rod.jpg').default,
            require('../img/media/best-in-rest/Hose_Lift/Flexiblel Arm.jpg').default,
            require('../img/media/best-in-rest/Hose_Lift/IMG_0750-edited.jpg').default,
            require('../img/media/best-in-rest/Hose_Lift/IMG_2736-edited.jpg').default,
            require('../img/media/best-in-rest/Hose_Lift/IMG_2741-edited.jpg').default,
            require('../img/media/best-in-rest/Hose_Lift/Open-Box.jpg').default,
            require('../img/media/best-in-rest/Hose_Lift/Retractable Tip.jpg').default,
            require('../img/media/best-in-rest/Hose_Lift/Silicone on Clamp.jpg').default,
          ]}/>
          <AlbumRow title = 'Levätä Weighted Blanket'
          files={[
            require('../img/media/best-in-rest/n/levata/Blanket-Cuddled-in-Bed.jpg').default,
            require('../img/media/best-in-rest/n/levata/Blanket-Sitting-in-Bed.jpg').default,
            require('../img/media/best-in-rest/n/levata/Folded-Blanket.jpg').default,
            require('../img/media/best-in-rest/n/levata/Blanket-Lying-in-Bed.jpg').default,
            require('../img/media/best-in-rest/n/levata/Blanket-Sitting-on-Chair.jpg').default,
            require('../img/media/best-in-rest/n/levata/Rolled-Blanket.jpg').default
          ]}/>
          <AlbumRow title = 'Chin Strap'
          files={[
            require('../img/media/best-in-rest/n/chin-strap/cpap-chin-strap.jpg').default,
            require('../img/media/best-in-rest/n/chin-strap/cpap-chin-strap2.jpg').default,
            require('../img/media/best-in-rest/n/chin-strap/cpap-chin-strap3.jpg').default,
            require('../img/media/best-in-rest/n/chin-strap/cpap-chin-strap4.jpg').default,
            require('../img/media/best-in-rest/n/chin-strap/cpap-chin-strap5.jpg').default,
          ]}/>
          <AlbumRow title = 'Memory Foam CPAP Pillow '
          files={[
            require('../img/media/best-in-rest/n/pillow-memory-foam-cpap/cpap-memory-foam-pillow-03.jpg').default,
            require('../img/media/best-in-rest/n/pillow-memory-foam-cpap/cpap-memory-foam-pillow-07.jpg').default,
            require('../img/media/best-in-rest/n/pillow-memory-foam-cpap/cpap-memory-foam-pillow-08.jpg').default,
            require('../img/media/best-in-rest/n/pillow-memory-foam-cpap/cpap-memory-foam-pillow-box-web.jpg').default,
            require('../img/media/best-in-rest/n/pillow-memory-foam-cpap/cpap-memory-foam-pillow-man-sleeping.jpg').default,
          ]}/>
          <AlbumRow title = 'Memory Foam CPAP Pillow with Cooling Gel'
          files={[
            require('../img/media/best-in-rest/n/pillow-memory-foam-cpap-with-cooling-gel/pillow-memory-foam-cpap-with-cooling-gel-01.jpg').default,
            require('../img/media/best-in-rest/n/pillow-memory-foam-cpap-with-cooling-gel/pillow-memory-foam-cpap-with-cooling-gel-02.jpg').default,
            require('../img/media/best-in-rest/n/pillow-memory-foam-cpap-with-cooling-gel/pillow-memory-foam-cpap-with-cooling-gel-03.jpg').default,
            require('../img/media/best-in-rest/n/pillow-memory-foam-cpap-with-cooling-gel/pillow-memory-foam-cpap-with-cooling-gel-04.jpg').default
          ]}/>
          <AlbumRow title = 'Adjustable Memory Foam Pillow for Children'
          files={[
            require('../img/media/best-in-rest/n/pillow-memory-foam-for-children/memory-foam-pillow-for-children-01.jpg').default,
            require('../img/media/best-in-rest/n/pillow-memory-foam-for-children/memory-foam-pillow-for-children-02.jpg').default,
            require('../img/media/best-in-rest/n/pillow-memory-foam-for-children/memory-foam-pillow-for-children-03.jpg').default,
            require('../img/media/best-in-rest/n/pillow-memory-foam-for-children/memory-foam-pillow-for-children-04.jpg').default,
            require('../img/media/best-in-rest/n/pillow-memory-foam-for-children/memory-foam-pillow-for-children-05.jpg').default,
            require('../img/media/best-in-rest/n/pillow-memory-foam-for-children/memory-foam-pillow-for-children-06.jpg').default,
            require('../img/media/best-in-rest/n/pillow-memory-foam-for-children/memory-foam-pillow-for-children-07.jpg').default,
            require('../img/media/best-in-rest/n/pillow-memory-foam-for-children/memory-foam-pillow-for-children-08.jpg').default,
            require('../img/media/best-in-rest/n/pillow-memory-foam-for-children/memory-foam-pillow-for-children-airway.jpg').default,
            require('../img/media/best-in-rest/n/pillow-memory-foam-for-children/memory-foam-pillow-for-children-breakdown.jpg').default,
            require('../img/media/best-in-rest/n/pillow-memory-foam-for-children/memory-foam-pillow-for-children-spine.jpg').default,
          ]}/>
          <AlbumRow title = 'Luxury Memory Foam Anti-Fatigue Eye Mask'
          files={[
            require('../img/media/best-in-rest/n/eye-mask/eye-mask-1.jpg').default,
            require('../img/media/best-in-rest/n/eye-mask/eye-mask-2.jpg').default,
            require('../img/media/best-in-rest/n/eye-mask/eye-mask-3.jpg').default,
            require('../img/media/best-in-rest/n/eye-mask/eye-mask-4.jpg').default,
            require('../img/media/best-in-rest/n/eye-mask/eye-mask-model-1.jpg').default,
            require('../img/media/best-in-rest/n/eye-mask/eye-mask-model-2.jpg').default,
            require('../img/media/best-in-rest/n/eye-mask/eye-mask-model-3.jpg').default,
          ]}/>
        </div>
      </div>
      
    );
  }
}