import React from 'react';
import * as ReactBootStrap from "react-bootstrap";
import {
  BrowserRouter as Router,
  Link,
  withRouter
} from 'react-router-dom';
import "./common.css";

import logo from "../logo.png";
import logosmall from "../logo-small.png";

class NavBar extends React.Component {
  constructor(){
    super();
    this.state = {
      height: window.innerHeight,
      width: window.innerWidth
    }
  }

  handleResize=()=>{
    this.setState({
      ['height']: window.innerHeight
    });
    this.setState({
      ['width']: window.innerWidth
    });
  }

  medium = 860;

  small = 575;

  render(){
    window.onresize = this.handleResize;
    return (
      <div className="NavBar">
        <div className="spacer"/>

        <ReactBootStrap.Navbar style={{paddingTop: "0", paddingBottom: "0"}} className="bsnavbar" fixed="top" collapseOnSelect expand="sm" variant="dark" >
          <Link to="/">
            <ReactBootStrap.Navbar.Brand><img className="navbar-brand" src={logo}/></ReactBootStrap.Navbar.Brand>
          </Link>
          <ReactBootStrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <ReactBootStrap.Navbar.Collapse id="responsive-navbar-nav">
            <ReactBootStrap.Nav className="mr-auto">
                {this.props.location.pathname==="/products" && <button className="checked">Our Products</button>}
                {this.props.location.pathname!=="/products" && <Link to="/products"><button>Our Products</button></Link>}

                {this.props.location.pathname.includes("/products/purdoux") && <Link to="/products/purdoux"><button className="checked">Purdoux</button></Link>}
                {!this.props.location.pathname.includes("/products/purdoux") && <Link to="/products/purdoux"><button>Purdoux</button></Link>}

                {this.props.location.pathname.includes("/products/medistrom") && <Link to="/products/medistrom"><button className="checked">Medistrom</button></Link>}
                {!this.props.location.pathname.includes("/products/medistrom") && <Link to="/products/medistrom"><button>Medistrom</button></Link>}

                {this.props.location.pathname.includes("/products/best-in-rest") && <Link to="/products/best-in-rest"><button className="checked">Best In Rest</button></Link>}
                {!this.props.location.pathname.includes("/products/best-in-rest") && <Link to="/products/best-in-rest"><button>Best In Rest</button></Link>}

                {(this.props.location.pathname.includes("/community-support")||this.props.location.pathname.includes("/about-us")) && 
                <ReactBootStrap.DropdownButton className="navbar-dropdown-active" title="About Us" variant="">
                  <ReactBootStrap.Dropdown.Item href={process.env.PUBLIC_URL+"/community-support"}>Supporting the Community</ReactBootStrap.Dropdown.Item>
                  <ReactBootStrap.Dropdown.Item href={process.env.PUBLIC_URL+"/about-us"}>About Us</ReactBootStrap.Dropdown.Item>
                </ReactBootStrap.DropdownButton>}
                {(!this.props.location.pathname.includes("/community-support")&&!this.props.location.pathname.includes("/about-us")) && 
                <ReactBootStrap.DropdownButton className="navbar-dropdown" title="About Us" variant="">
                  <ReactBootStrap.Dropdown.Item href={process.env.PUBLIC_URL+"/community-support"}>Supporting the Community</ReactBootStrap.Dropdown.Item>
                  <ReactBootStrap.Dropdown.Item href={process.env.PUBLIC_URL+"/about-us"}>About Us</ReactBootStrap.Dropdown.Item>
                </ReactBootStrap.DropdownButton>}
                

            </ReactBootStrap.Nav>
            <ReactBootStrap.Nav className="contact-nav">
            <Link to="/contact-us">
               <button className="contact-us-nav"><span>Contact Us</span></button>
            </Link>
            </ReactBootStrap.Nav>
          </ReactBootStrap.Navbar.Collapse>
        </ReactBootStrap.Navbar>
        <ReactBootStrap.Navbar style={{paddingTop: "0", paddingBottom: "0"}} className="bsnavbar-medium" fixed="top" collapseOnSelect expand="sm" variant="dark" >
          <Link to="/">
            <ReactBootStrap.Navbar.Brand><img className="navbar-brand" src={logosmall}/></ReactBootStrap.Navbar.Brand>
          </Link>
          <ReactBootStrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <ReactBootStrap.Navbar.Collapse id="responsive-navbar-nav">
            <ReactBootStrap.Nav className="mr-auto">
                {(this.props.location.pathname.includes("/products")||
                this.props.location.pathname.includes("/purdoux")||
                this.props.location.pathname.includes("/medistrom")||
                this.props.location.pathname.includes("/best-in-rest")) && 
                <ReactBootStrap.Dropdown className="navbar-button-dropdown-active" variant="" as={ReactBootStrap.ButtonGroup} >
                  <Link to="/products"><ReactBootStrap.Button variant = "" style={{width: '20vw'}}>Our Products</ReactBootStrap.Button></Link>
                  <ReactBootStrap.Dropdown.Toggle split variant="" id="dropdown-split-basic" />
                  <ReactBootStrap.Dropdown.Menu>
                    <ReactBootStrap.Dropdown.Item href={process.env.PUBLIC_URL+"/products/purdoux"}>Purdoux</ReactBootStrap.Dropdown.Item>
                    <ReactBootStrap.Dropdown.Item href={process.env.PUBLIC_URL+"/products/medistrom"}>Medistrom</ReactBootStrap.Dropdown.Item>
                    <ReactBootStrap.Dropdown.Item href={process.env.PUBLIC_URL+"/products/best-in-rest"}>Best In Rest</ReactBootStrap.Dropdown.Item>
                  </ReactBootStrap.Dropdown.Menu>
                </ReactBootStrap.Dropdown>}
                {(!this.props.location.pathname.includes("/products")&&
                !this.props.location.pathname.includes("/purdoux")&&
                !this.props.location.pathname.includes("/medistrom")&&
                !this.props.location.pathname.includes("/best-in-rest")) && 
                <ReactBootStrap.Dropdown className="navbar-button-dropdown" title="Our Products" variant="" as={ReactBootStrap.ButtonGroup} >
                  <Link to="/products"><ReactBootStrap.Button variant = "" style={{width: '20vw'}}>Our Products</ReactBootStrap.Button></Link>
                  <ReactBootStrap.Dropdown.Toggle split variant="" id="dropdown-split-basic" />
                  <ReactBootStrap.Dropdown.Menu>
                    <ReactBootStrap.Dropdown.Item href={process.env.PUBLIC_URL+"/products/purdoux"}>Purdoux</ReactBootStrap.Dropdown.Item>
                    <ReactBootStrap.Dropdown.Item href={process.env.PUBLIC_URL+"/products/medistrom"}>Medistrom</ReactBootStrap.Dropdown.Item>
                    <ReactBootStrap.Dropdown.Item href={process.env.PUBLIC_URL+"/products/best-in-rest"}>Best In Rest</ReactBootStrap.Dropdown.Item>
                  </ReactBootStrap.Dropdown.Menu>
                </ReactBootStrap.Dropdown>}

                {(this.props.location.pathname.includes("/community-support")||this.props.location.pathname.includes("/about-us")||this.props.location.pathname.includes("/contact-us")) && 
                <ReactBootStrap.DropdownButton className="navbar-dropdown-active" title="About Us" variant="" >
                  <ReactBootStrap.Dropdown.Item href={process.env.PUBLIC_URL+"/community-support"}>Supporting the Community</ReactBootStrap.Dropdown.Item>
                  <ReactBootStrap.Dropdown.Item href={process.env.PUBLIC_URL+"/about-us"}>About Us</ReactBootStrap.Dropdown.Item>
                  <ReactBootStrap.Dropdown.Item href={process.env.PUBLIC_URL+"/contact-us"}>Contact Us</ReactBootStrap.Dropdown.Item>
                </ReactBootStrap.DropdownButton>}
                {(!this.props.location.pathname.includes("/community-support")&&!this.props.location.pathname.includes("/about-us")&&!this.props.location.pathname.includes("/contact-us")) && 
                <ReactBootStrap.DropdownButton className="navbar-dropdown" title="About Us" variant="">
                  <ReactBootStrap.Dropdown.Item href={process.env.PUBLIC_URL+"/community-support"}>Supporting the Community</ReactBootStrap.Dropdown.Item>
                  <ReactBootStrap.Dropdown.Item href={process.env.PUBLIC_URL+"/about-us"}>About Us</ReactBootStrap.Dropdown.Item>
                  <ReactBootStrap.Dropdown.Item href={process.env.PUBLIC_URL+"/contact-us"}>Contact Us</ReactBootStrap.Dropdown.Item>
                </ReactBootStrap.DropdownButton>}
            </ReactBootStrap.Nav>
          </ReactBootStrap.Navbar.Collapse>
        </ReactBootStrap.Navbar>
        <ReactBootStrap.Navbar style={{paddingTop: "0", paddingBottom: "0"}} className="bsnavbar-small" fixed="top" collapseOnSelect expand="sm" variant="dark" >
          <Link to="/">
            <ReactBootStrap.Navbar.Brand><img className="navbar-brand" src={logosmall}/></ReactBootStrap.Navbar.Brand>
          </Link>
          <ReactBootStrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <ReactBootStrap.Navbar.Collapse id="responsive-navbar-nav">
            <ReactBootStrap.Nav className="mr-auto">
                {this.props.location.pathname==="/products" && <Link to="/products"><button className="checked">Our Products</button></Link>}
                {this.props.location.pathname!=="/products" && <Link to="/products"><button>Our Products</button></Link>}

                {this.props.location.pathname.includes("/products/purdoux") && <Link to="/products/purdoux"><button className="checked">Purdoux</button></Link>}
                {!this.props.location.pathname.includes("/products/purdoux") && <Link to="/products/purdoux"><button>Purdoux</button></Link>}

                {this.props.location.pathname.includes("/products/medistrom") && <Link to="/products/medistrom"><button className="checked">Medistrom</button></Link>}
                {!this.props.location.pathname.includes("/products/medistrom") && <Link to="/products/medistrom"><button>Medistrom</button></Link>}

                {this.props.location.pathname.includes("/products/best-in-rest") && <Link to="/products/best-in-rest"><button className="checked">Best In Rest</button></Link>}
                {!this.props.location.pathname.includes("/products/best-in-rest") && <Link to="/products/best-in-rest"><button>Best In Rest</button></Link>}

                {this.props.location.pathname.includes("/community-support") && <Link to="/community-support"><button className="checked">Community Support</button></Link>}
                {!this.props.location.pathname.includes("/community-support") && <Link to="/community-support"><button>Community Support</button></Link>}

                {this.props.location.pathname.includes("/about-us") && <Link to="/about-us"><button className="checked">About Us</button></Link>}
                {!this.props.location.pathname.includes("/about-us") && <Link to="/about-us"><button>About Us</button></Link>}
            
                {this.props.location.pathname.includes("/contact-us") && <Link to="/contact-us"><button className="checked">Contact Us</button></Link>}
                {!this.props.location.pathname.includes("/contact-us") && <Link to="/contact-us"><button>Contact Us</button></Link>}
            </ReactBootStrap.Nav>
          </ReactBootStrap.Navbar.Collapse>
        </ReactBootStrap.Navbar> 
      </div>
    );
  }
}

export default withRouter(NavBar);