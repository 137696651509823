import React from 'react';
import './PageHeader.css';
import './BrandPage.css';

export default class PageHeader extends React.Component {
  constructor(){
    super();
  }
  render(){
    return (
      <div className="pp-header">
        <div className="pp-header-container">
          <h1>{this.props.title}</h1>
        </div>
      </div>
    );
  }
}