import React from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import slide_one from "../img/light-cover1.jpg";
import logo_one from "../img/logos/logo-1.png";
import logo_two from "../img/logos/logo-2.png";
import logo_three from "../img/logos/logo-3.png";
import './common.css';

import {
  BrowserRouter as Router,
  Link
} from 'react-router-dom';

export default class HomePage extends React.Component {
  constructor(){
    super();
  }
  mediumBanner = 1200;
  small = 480;
  
  mediumMission = 579;

  mediumBrand = 625;

  slide_one_url = '../img/light-cover1.jpg';
  render(){
    const bannersettings = {
      dots: true,
      infinte: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: "banner",
      arrows:true,
      
    }
    
    const aboutUsTitle={
      fontSize: '2.6em',
      lineHeight: '44px',
      margin: '0 0 44px 0',
      color: '#0e0e0e',
      fontWeight: '200',
      letterSpacing:'-1px',
      fontFamily:'inherit',
      letterSpacing: '-1px'
    }
    const aboutUsText={
      fontSize: '1.6em',
      lineHeight: '1.5em',
      marginBottom: '20px',
      fontWeight: '300',
      color: '#777777',
      fontFamily: 'inherit',
      margin: '0 auto',
    }
    return (
      <div className="page">
         <div className={`banner-container`}>
          <Slider {...bannersettings}>
          <div className="slide-two">
              
                <div className="coltwo">
                  <h4 className={`slidetwotitle4`}>Supporting the latest devices!</h4>
                  <br className={`slidetwobreak`}/>
                  <h3 className={`slidetwotitle1`}>AirSense 11,  Löwenstein Medical prismaSMART & DreamStation 2</h3>
                  <br className={`slidetwobreak`}/>
                  <Link to="/products/medistrom/pilot12lite"><button className={`slidetwobutton`} style={{marginRight:"15px"}}>Pilot 12 Lite</button></Link>
                  <Link to="/products/medistrom/pilot24lite"><button className={`slidetwobutton`}>Pilot 24 Lite</button></Link>
                </div>
                <div className="coltwo">
                  <img className={`slidetwoimg`} src={require("../img/media/medistrom/n/pilot24-lite/AirSense 11 TRAVEL nobg.png").default}/>
                </div>
              </div>

              <div className="slide-two">
                <div className="coltwo">
                  <h4 className={`slidetwotitle4`}>We are excited to launch our very own</h4>
                  <br className={`slidetwobreak`}/>
                  <h2 className={`slidetwotitle2`}><b>Hand Sanitizer!</b></h2>
                  <br className={`slidetwobreak`}/>
                  <h3 className={`slidetwotitle3`}>A refreshing unscented formula enriched with Aloe Vera</h3>
                  <br className={`slidetwobreak`}/>
                  <Link to="/products/purdoux/handsanitizer"><button className={`slidetwobutton`}>View Product</button></Link>
                </div>
                <div className="coltwo">
                  <img className={`slidetwoimg`} src={require("../img/hand-sanitizer.png").default}/>
                </div>
              </div>
              <div className={`slide-one`}>
              </div>
          </Slider>
        </div>
        <div className="mission-container">
          <div className="row">
            <div className="col">
              <p className={`missionText`}>We Provide High Quality<em className={`missionTextHighlight`}> Medical Supplies <br/> 
                and Accessories </em>for Your Family Health </p>
            </div>
            <div className="col">
              <Link to="/contact-us">
              <button className={`missionButton`}><span>Contact Us</span></button>
              </Link>
            </div>
          </div>
        </div>
        <div className="about-us">
          <div className = "row">
            <h2 style={aboutUsTitle}>Welcome to Choice One Medical - a wholesaler and distributor of Medical Supplies and Accessories.</h2>
          </div>
          <div className = "row">
            <p style={aboutUsText}>At Choice One Medical we strive to provide high quality medical products to our clients following the latest innovations and modern designs with cutting edge technology that brings the healthcare to the highest standards.<br/><br/> </p>
          </div>
          <div className = "row">
            <p style={aboutUsText}>To Become Our Dealer, please contact us:</p>
          </div>
          <div className = "row">
            <p style={aboutUsText}> North America 1-888-400-8987, Australia 1-300-146-358, EU: 44-800-060-8576, <br/>
              Other Regions: +1-647-846-7700, <br/><br/>

              or email <a href="mailto:sales@choiceonemedical.com">sales@choiceonemedical.com</a></p>
          </div>
        </div>
        <div className = {`our-brands`}>
          <hr/>
          <h2>Our Brands</h2>
          <Link to="/products/medistrom">
            <div className="colthree"><img style={{marginBottom: '60px', width:'100%'}} src={logo_one}/></div>
          </Link>
          <Link to="/products/best-in-rest">
            <div className="colthree"><img style={{marginBottom: '60px', width:'100%'}} src={logo_two}/></div>
          </Link>
          <Link to="/products/purdoux">
            <div className="colthree"><img style={{marginBottom: '60px', width:'100%'}} src={logo_three}/></div>
          </Link>
        </div>
      </div>
    );
  }
}