import React from 'react';
import PageHeader from "./PageHeader";
import ProductRow from './ProductRow';
import data from '../data.json';
import './BrandPage.css';
import logo from "../img/logos/logo-1.png";


export default class Medistrom extends React.Component {
  constructor(){
    super();
  }
  render(){
    return (
      <div className="product-page">
        <PageHeader title={"Our Products"}/>
        <div className="product-page-container">
          <div className="brand">
            <img src={logo}/>
          </div>
          <div className="brand-description">
            Medistrom™ produces the best quality and most reliable backup power supply. <br/><br/>
            Medistrom™ small, lightweight multipurpose back-up power supplies allow you the freedom to power your CPAP machine no matter where your world takes you!<br/><br/>
            To become our dealer, please contact us at (888) 400-8987.<br/>
          </div>
          <ProductRow obj={data.pilot12lite_obj}/>
          <ProductRow obj={data.pilot24lite_obj}/>
          <ProductRow obj={data.carcharger_obj}/>
          <ProductRow obj={data.solarpanel_obj}/>
          <ProductRow obj={data.airsense11kit_obj}/>
          <ProductRow obj={data.ds2cable_obj}/>
          <ProductRow obj={data.lowensteincable_obj}/>
          <ProductRow obj={data.s9kit_obj}/>
          <ProductRow obj={data.dreamstation_obj}/>
          <ProductRow obj={data.ichkit_obj}/>
          <ProductRow obj={data.acadapter_obj}/>
          <ProductRow obj={data.mixedkit_obj}/>
        </div>
      </div>
      
    );
  }
}