import React from 'react';
import PageHeader from "./PageHeader";
import "./AboutUs.css";
import './BrandPage.css';

export default class AboutUs extends React.Component {


  render(){
    return (
      <div className="product-page">
        <PageHeader title={"About Us"}/>
        <div className="product-page-container">
          <div className="row" style={{marginLeft: '15px', paddingRight:'30px'}}>
            <p>Choice One Medical is a Global manufacturer and distributor of medical accessories, with a focus on products relevant to respiratory, sleep and disinfectants. Founded in 2015, Choice One Medical strives to provide the highest quality products to medical professionals and dealers, across the globe. Now with multiple distribution offices located in Ontario, Canada, California USA, Florida USA, South Australia and The Netherlands, we are proud to have a strong reputation on not only our products but superior service and support. </p>
            <br/>
            <br/>
            <p>Following the latest innovations, modern designs and cutting edge technology we are dedicated to being your reliable source for medical accessories for respiratory, sleep and disinfectants. We tremendously value and appreciate the relationships we have built with our clients and continuously invest in ourselves and our products to provide certified and safe items for day to day use.</p>
          </div>
          <div className="row" style={{marginLeft: '15px', paddingRight:'30px'}}>
            <a href="https://www.iheartradio.ca/am800/audio/the-shift-the-upside-choice-one-medical-1.17240290"><h3 style={{color: "#0B2D37"}}>THE SHIFT - THE UPSIDE - CHOICE ONE MEDICAL</h3></a>
          </div>
          <div className="row" style={{marginLeft: '15px', paddingRight:'30px'}}>
          <a href="https://www.iheartradio.ca/am800/audio/the-shift-the-upside-choice-one-medical-1.17240290"><img   style={{maxWidth: '400px', maxHeight:'400px'}}src={require("../img/community/image-jpg-t-1645825844-size-Large.jpg").default}/></a>
          </div>
          <hr/>
          <div className="row" style={{marginLeft: '15px', marginRight:'15px'}}>
            <h2>Our Clients</h2>
          </div>
          <div className="row" style={{marginLeft: '15px', marginRight:'15px'}}>
            <h3 style={{color: "#0B2D37"}}>Canada</h3>
          </div>
          <div className="row" style={{marginLeft: '15px', marginRight:'15px'}}>
            <img className="clientimg" src={require("../img/clients/MLSE-logo.png").default} />
            <img className="clientimg" src={require("../img/clients/medigas.jpg").default} />
            <img className="clientimg" src={require("../img/clients/vitalaire.png").default} />
            <img className="clientimg" src={require("../img/clients/wellwise.png").default} />
            <img className="clientimg" src={require("../img/clients/Forzani_Group_logo.png").default} />
            <img className="clientimg" src={require("../img/clients/logo fond bleu 2019.jpg").default} />
          </div>
          <div className="row" style={{marginLeft: '15px', marginRight:'15px'}}>
            <h3 style={{color: "#0B2D37"}}>USA</h3>
          </div>
          <div className="row" style={{marginLeft: '15px', marginRight:'15px'}}>
            <img className="clientimg" src={require("../img/clients/MicrosoftTeams-image (8).png").default} />
            <img className="clientimg" src={require("../img/clients/cpap.png").default} />
            <img className="clientimg" src={require("../img/clients/Linde_plc.jpg").default} />
            <img className="clientimg" src={require("../img/clients/Apria_Healthcare_Logo.jpg").default} />
          </div>
          <div className="row" style={{marginLeft: '15px', marginRight:'15px'}}>
            <h3 style={{color: "#0B2D37"}}>EU</h3>
          </div>
          <div className="row" style={{marginLeft: '15px', marginRight:'15px'}}>
            <img className="clientimg" src={require("../img/clients/lowenstein.png").default} />
            <img className="clientimg" src={require("../img/clients/LOGO-VIVISOL.jpg").default} />
            <img className="clientimg" src={require("../img/clients/airliquide.JPG").default} />
            <img className="clientimg" src={require("../img/clients/TC Logo RGB.png").default} />
          </div>
        </div>
      </div>
    );
  }
}