import React from 'react';
import ProductRow from './ProductRow';
import PageHeader from './PageHeader';
import data from '../data.json';
import './BrandPage.css';
import logo from "../img/logos/logo-3.png";

export default class Purdoux extends React.Component {
  constructor(){
    super();
  }
  render(){
    
    return (
      <div className="product-page">
        <PageHeader title={"Our Products"}/>
        <div className="product-page-container">
          <div className="brand">
            <img src={logo}/>
          </div>
          <div className="brand-description">
            To become our dealer, please contact us at (888) 400-8987.
          </div>
          <ProductRow  obj={data.cpapfilters_obj}/>
          <ProductRow  obj={data.dustcover_obj}/>
          <ProductRow  obj={data.sanitizingwipes_obj}/>
          <ProductRow  obj={data.disinfectingwipes_obj}/>
          <ProductRow  obj={data.handsanitizer_obj}/>
          <ProductRow  obj={data.hosestandard_obj}/>
          <ProductRow  obj={data.hoseslim_obj}/>
          <ProductRow  obj={data.aloecan_obj}/>
          <ProductRow  obj={data.citruscan_obj}/>
          <ProductRow  obj={data.aloetravel_obj}/>
          <ProductRow  obj={data.citrustravel_obj}/>
          <ProductRow  obj={data.childwipes_obj}/>
          <ProductRow  obj={data.unscentedsoap_obj}/>
          <ProductRow  obj={data.scentedsoap_obj}/>
          <ProductRow  obj={data.twoinonebrush_obj}/>
          <ProductRow  obj={data.hosebrush_obj}/>
        </div>
      </div>
    );
  }
}