import React from 'react';
import './ImageModal.css';

export default class ImageModal extends React.Component {
    constructor(){
        super();
      }
    state={
        modalState: false
    }

    toggleState =() =>{
        this.setState({
            modalState: !this.state.modalState
        });
    }

  render(){
    return (
     <div className="image-modal">
         <button className={`modal-background-${this.state.modalState} modal-showing-${this.state.modalState}`} onClick={()=>this.toggleState()}>
            <div className="modal-inner">
                <img className="modal-image-opened" src={this.props.imgSrc}/>
            </div>
         </button>
         <img className="modal-image" src={this.props.imgSrc} onClick={()=>this.toggleState()}/>

     </div>
    );
  }
}