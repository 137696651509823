import React from 'react';
import './ContactUs.css';
import './PageHeader.css';
import Form from "./Form";

export default class ContactUs extends React.Component {
  constructor(){
    super();
  }
  render(){
    return (
      <div className="contact-us">
        <div className="pp-header">
          <div className="pp-header-container">
            <h1>Contact Us</h1>
          </div>
        </div>
        <div className="contact-us-container">
          <div className="company">
            <h2>Choice One Medical</h2>
          </div>
          <div className="contact-info">
            <div className="col">
              <h3>
                Head Office:
              </h3>
              <br/>
              1 Westside dr., unit 2A, Toronto, ON M9C 1B3 Canada<br/><br/>

              <b>Tel.:</b> (647) 846-7700;<br/>
              <b>Toll-free:</b> (888)-400-8987;<br/>
              <b>Fax:</b> (647) 846-1263<br/><br/>

              <b>Canada and USA:</b> 1-888-400-8987<br/><br/>

              <b>Australia:</b> 1-300-146-358<br/><br/>

              <b>EU:</b> 44-800-060-8576<br/><br/>

              <b>Other Regions:</b> +1-647-846-7700<br/><br/>

              <b>E-mail:</b> info@choiceonemedical.com<br/>
            </div>
            <div className="col">
              <h3>
                Our warehouses:
              </h3>
              <br/>
              <b>CANADA</b><br/>
              1 Westside dr., unit 2A, Toronto, ON, M9C 1B3<br/><br/>

              <b>USA</b><br/>
              EAST USA 3131 Caruso crt., Orlando, FL, 32807<br/>
              WEST USA 1545 E Locust St., Ontario, CA, 91761<br/><br/>

              <b>EUROPE</b><br/>
              Gerolsteinbaan 7-9, 5121 DN Rijen, The Netherlands<br/><br/>

              <b>AUSTRALIA</b><br/>
              348 Richmond Rd., Netley, SA 5037, Australia <br/>
            </div>
          </div>
        </div>
        <div className="contact-us-container">
          <hr/>
          <h2>Online Request</h2>
          <Form/>
        </div>
      </div>
    );
  }
}